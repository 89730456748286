import { useState, useEffect, useRef } from 'react';
import { Howl } from 'howler';
import { Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import './Audio.css';
import { PauseIcon, PlayIcon } from '../../../assets/icons/icons';
import { formatMediaPath, formatTime } from '../../../assets/utils/utils';

const useStyles = makeStyles({
  seekSlider: {
    color: 'var(--about-yellow-accent-color) !important',
  },
});

function Audio({ data }) {
  const classes = useStyles();
  const sound = useRef(null);
  const intervalRef = useRef(null);
  const [duration, setDuration] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const shift = duration ? (currentTime * 100) / duration : 0;

  useEffect(() => {
    sound.current = new Howl({
      src: formatMediaPath(data),
      html5: true,
      onload: () => {
        setDuration(sound.current.duration());
      },
      onplay: () => {
        setIsPlaying(true);
        intervalRef.current = setInterval(() => {
          setCurrentTime(sound.current.seek());
        }, 100);
      },
      onpause: () => {
        setIsPlaying(false);
        setCurrentTime(sound.current.seek());
        clearInterval(intervalRef.current);
      },
      onend: () => {
        setIsPlaying(false);
        setCurrentTime(0);
        clearInterval(intervalRef.current);
      },
      onloaderror: (error) => {
        console.log(`a load error has occured:`, error);
      },
      onplayerror: (error) => {
        console.log(`a play error has occured:`, error);
      },
    });

    return () => {
      if (sound.current) sound.current.unload();
      clearInterval(intervalRef.current);
    };
  }, [data]);

  function togglePlay() {
    isPlaying ? sound.current.pause() : sound.current.play();
  }

  const seekHandler = (e, value) => {
    const seek = (duration * value) / 100;
    sound.current.seek(Math.round(seek));
    setCurrentTime(seek);
  };

  return (
    <div className="audio">
      <div className="audio__info">
        <button
          className="audio__control-btn"
          type="button"
          onClick={togglePlay}
        >
          {isPlaying ? (
            <PauseIcon
              mainClassName="audio__control-icon"
              fillClassName="audio__control-icon-fill"
            />
          ) : (
            <PlayIcon
              mainClassName="audio__control-icon"
              fillClassName="audio__control-icon-fill"
            />
          )}
        </button>
        <p className="audio__title">{data.caption}</p>
        {duration && (
          <p className="audio__text">
            {formatTime(currentTime)} / {formatTime(duration)}
          </p>
        )}
      </div>

      <div className="audio__slider-box">
        <Slider
          className={`${classes.seekSlider}`}
          value={shift}
          onChange={seekHandler}
        />
      </div>
    </div>
  );
}

export default Audio;
