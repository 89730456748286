import { HEADING_BLOCK } from '../../../../assets/utils/constants';
import { renderArticleChildren } from '../../../../assets/utils/utils';
import './TextElement.css';

function TextElement({ data }) {
  const { type, level, children } = data;

  return (
    <div className="text-element">
      {type === HEADING_BLOCK && level === 1 ? (
        <h2 className="text-element__title">
          {renderArticleChildren(children)}
        </h2>
      ) : type === HEADING_BLOCK && level === 2 ? (
        <h3 className="text-element__subtitle">
          {renderArticleChildren(children)}
        </h3>
      ) : type === HEADING_BLOCK && level === 3 ? (
        <h4 className="text-element__small-title">
          {renderArticleChildren(children)}
        </h4>
      ) : (
        <p className="text-element__text">{renderArticleChildren(children)}</p>
      )}
    </div>
  );
}

export default TextElement;
