import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenful from 'screenfull';
import './Video.css';
import { MAIN_URL } from '../../../assets/utils/constants';
import { PlayIcon } from '../../../assets/icons/icons';
import Control from './Control/Control';
import { formatMediaPath, formatTime } from '../../../assets/utils/utils';

let count = 0;

function Video({ data }) {
  const videoPlayerRef = useRef(null);
  const controlRef = useRef(null);
  const playerContainerRef = useRef(null);

  const [isPreview, setIsPreview] = useState(Boolean(data.previewUrl));
  const [videoState, setVideoState] = useState({
    playing: Boolean(data.previewUrl),
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  const { playing, muted, volume, played, seeking, buffer } = videoState;

  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : '00:00';
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : '00:00';

  const formatCurrentTime = formatTime(currentTime);
  const formatDuration = formatTime(duration);

  const previewClickHandler = () => {
    setIsPreview(false);
  };

  const playPauseHandler = () => {
    // plays and pause the video (toggling)
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const stopHandler = () => {
    // ended video handler
    setVideoState({ ...videoState, playing: !videoState.playing });
    count = 0;
    controlRef.current.style.visibility = 'visible';
  };

  const rewindHandler = () => {
    // rewinds the video player reducing 5
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
  };

  const fastFowardHandler = () => {
    // fastFowards the video player by adding 10
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  const progressHandler = (state) => {
    if (count > 3) {
      controlRef.current.style.visibility = 'hidden'; // toggling player control container
    } else if (controlRef.current.style.visibility === 'visible') {
      count += 1;
    }

    if (!seeking) setVideoState({ ...videoState, ...state });
  };

  const seekHandler = (e, value) => {
    setVideoState({ ...videoState, played: parseFloat(value / 100) });
    videoPlayerRef.current.seekTo(parseFloat(value / 100));
  };

  const seekMouseUpHandler = (e, value) => {
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(value / 100);
  };

  const seekMouseDownHandler = (e) => {
    setVideoState({ ...videoState, seeking: true });
  };

  const volumeChangeHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
    });
  };

  const volumeSeekUpHandler = (e, value) => {
    const newVolume = parseFloat(value) / 100;

    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false,
    });
  };

  const muteHandler = () => {
    // mutes the video player
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  const bufferStartHandler = () => {
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = () => {
    setVideoState({ ...videoState, buffer: false });
  };

  const toggleFullScreen = () => {
    if (screenful.isEnabled) {
      screenful.toggle(playerContainerRef.current);
    }
  };

  const mouseLeaveHandler = () => {
    controlRef.current.style.visibility = 'hidden';
    count = 0;
  };

  const mouseMoveHandler = () => {
    if (!controlRef.current) return;
    // if (controlRef.current.style.visibility === 'visible') return;

    controlRef.current.style.visibility = 'visible';
    count = 0;
  };

  return (
    <div
      className="video"
      ref={playerContainerRef}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <ReactPlayer
        ref={videoPlayerRef}
        className="video__player"
        width="100%"
        height="100%"
        url={formatMediaPath(data)}
        light={data.previewUrl ? `${MAIN_URL}${data.previewUrl}` : false}
        playIcon={
          <PlayIcon
            mainClassName="video__control-icon"
            fillClassName="video__control-icon-fill"
          />
        }
        playing={playing}
        volume={volume}
        muted={muted}
        onClickPreview={previewClickHandler}
        onProgress={progressHandler}
        onBuffer={bufferStartHandler}
        onBufferEnd={bufferEndHandler}
        onReady={bufferEndHandler}
        onEnded={stopHandler}
      />

      {isPreview && data.caption ? (
        <div className="video__title-box">
          <p className="video__title">{data.caption}</p>
        </div>
      ) : null}

      {!isPreview ? (
        <Control
          title={data.caption}
          controlRef={controlRef}
          onPlayPause={playPauseHandler}
          playing={playing}
          onRewind={rewindHandler}
          onForward={fastFowardHandler}
          played={played}
          onSeek={seekHandler}
          onSeekMouseUp={seekMouseUpHandler}
          onSeekMouseDown={seekMouseDownHandler}
          volume={volume}
          onVolumeChange={volumeChangeHandler}
          onVolumeSeekUp={volumeSeekUpHandler}
          mute={muted}
          onMute={muteHandler}
          duration={formatDuration}
          currentTime={formatCurrentTime}
          buffer={buffer}
          isScreenfulEnabled={screenful.isEnabled}
          onToggleFullScreen={toggleFullScreen}
        />
      ) : null}
    </div>
  );
}

export default Video;
