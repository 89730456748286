import { Route, Routes } from 'react-router-dom';
import banner from '../../assets/images/us/banner.webp';
import {
  ABOUT_EVENTS_LINK,
  ABOUT_NAV_LIST,
  ABOUT_WE_DO_LINK,
  ABOUT_WHY_US_LINK,
} from '../../assets/utils/constants';
import './About.css';
import BigBro from './BigBro/BigBro';
import WhatWeDo from './WhatWeDo/WhatWeDo';
import WhyUs from './WhyUs/WhyUs';
import Events from './Events/Events';
import PageNav from '../PageNav/PageNav';

function About() {
  return (
    <div className="about">
      <img className="about__banner" src={banner} alt="Это Мы" />
      <div className="about__nav">
        <PageNav list={ABOUT_NAV_LIST} />
      </div>

      <div className="about__content">
        <Routes>
          <Route index element={<BigBro />} />
          <Route path={`/${ABOUT_WE_DO_LINK}`} element={<WhatWeDo />} />
          <Route path={`/${ABOUT_WHY_US_LINK}`} element={<WhyUs />} />
          <Route path={`/${ABOUT_EVENTS_LINK}`} element={<Events />} />
        </Routes>
      </div>
    </div>
  );
}

export default About;
