import { Link } from 'react-router-dom';
import { PROFILE_LINK, SMALL_IMG_FORMAT } from '../../assets/utils/constants';
import './PersonBox.css';
import { formatMediaPath } from '../../assets/utils/utils';

function PersonBox({ data, type }) {
  return (
    <div className={`person ${type ? `person_type_${type}` : ''}`}>
      <Link
        className="person__container"
        to={`/${PROFILE_LINK}/${data.category?.translit_name}/${data.id}`}
      >
        <img
          className="person__photo"
          src={formatMediaPath(data.avatar, SMALL_IMG_FORMAT)}
          alt={data.avatar?.alternativeText}
        />
        <div className="person__info">
          <p className="person__nickname">{data.nickname}</p>
          <p className="person__name">{data.name}</p>
        </div>
      </Link>
    </div>
  );
}

export default PersonBox;
