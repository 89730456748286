import { Link } from 'react-router-dom';
import './NewsLinkBtn.css';

function NewsLinkBtn({ link, text }) {
  return (
    <Link className="main-link-btn" to={`/${link}`}>
      <p className="main-link-btn__text">{text}</p>
      <p className="main-link-btn__text main-link-btn__text_type_arrow">→</p>
    </Link>
  );
}

export default NewsLinkBtn;
