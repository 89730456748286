import { Link } from 'react-router-dom';
import './LinkCards.css';
import { FAMILY_LINK, MAIN_CARDS_LIST } from '../../../assets/utils/constants';

function LinkCards({ categories }) {
  const links = MAIN_CARDS_LIST.map(
    (card) =>
      categories?.find(
        (item) => item.name?.toLowerCase() === card.title.toLowerCase()
      )?.translit_name
  );

  return (
    <div className="main-cards">
      <ul className="main-cards__list">
        {MAIN_CARDS_LIST.map((item, i) => (
          <li className="main-cards__item" key={item.title}>
            <Link
              className="main-card__link"
              to={links[i] ? `/${FAMILY_LINK}/${links[i]}` : `/${FAMILY_LINK}`}
            >
              <div className="main-card__heading">
                <p className="main-card__title">{item.title}</p>
                <p className="main-card__arrow">→</p>
              </div>
              <img className="main-card__img" src={item.img} alt="" />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LinkCards;
