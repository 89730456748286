import { useEffect, useState } from 'react';
import InstagramCard from './InstagramCard/InstagramCard';
import './InstagramFeed.css';
import insta from '../../../assets/icons/instagram-icon.webp';
import { INSTA_LIMIT, INSTA_LINK } from '../../../assets/utils/constants';
import instaApi from '../../../assets/api/InstaApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function InstagramFeed() {
  const [posts, setPosts] = useState([]);
  const [isPreloader, setIsPreloader] = useState(true);

  useEffect(() => {
    setIsPreloader(true);
    instaApi
      .getPosts({ limit: INSTA_LIMIT })
      .then((res) => setPosts(res.posts))
      .catch((err) => console.log(err))
      .finally(() => setIsPreloader(false));
  }, []);

  return (
    <div className="insta-feed">
      <div className="insta-feed__link-container">
        <p className="insta-feed__title main-news__title">
          PRO <span className="insta-feed__title_type_color">CONTEEENT</span>
        </p>
        <a
          className="insta-feed__link"
          href={INSTA_LINK}
          target="_blank"
          rel="noreferrer"
        >
          <img className="insta-feed__link-icon" src={insta} alt="" />
        </a>
      </div>

      <div className="insta-feed__cards">
        {!isPreloader ? (
          <ul className="insta-feed__list">
            {posts.map((data) => (
              <li className="insta-feed__item" key={data.post_id}>
                <InstagramCard {...{ data }} />
              </li>
            ))}
          </ul>
        ) : (
          <MiniPreloader />
        )}
      </div>
    </div>
  );
}

export default InstagramFeed;
