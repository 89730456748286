import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Profile.css';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import {
  MEDIUM_IMG_FORMAT,
  THUMB_IMG_FORMAT,
} from '../../assets/utils/constants';
import Audio from './Audio/Audio';
import Gallery from './Gallery/Gallery';
import Video from './Video/Video';
import GigCard from '../Main/GigsMain/GigCard/GigCard';
import { formatMediaPath } from '../../assets/utils/utils';

function Profile() {
  const { category, artist_id } = useParams();
  const [data, setData] = useState(undefined);

  useEffect(() => {
    if (!(category && artist_id)) return;

    mainApi
      .getArtistById({ category, artist_id })
      .then((res) => setData(res))
      .catch((err) => console.log(err));
  }, [category, artist_id]);

  return (
    <section className="profile">
      {data ? (
        <div className="profile__container">
          <div className="profile__heading">
            <div className="profile__avatar-box">
              <img
                className="profile__avatar"
                src={formatMediaPath(data.avatar, MEDIUM_IMG_FORMAT)}
                alt={data.avatar?.alternativeText}
              />
              <div className="profile__name-box profile__name-box_type_mobile">
                <h1 className="profile__nickname">{data.nickname}</h1>
                <h2 className="profile__name">{data.name}</h2>
              </div>
              {/* social media links here */}
            </div>

            <div className="profile__info">
              <div className="profile__name-box">
                <h1 className="profile__nickname">{data.nickname}</h1>
                <h2 className="profile__name">{data.name}</h2>
              </div>

              <ul className="profile__main-info-list">
                {data.personal_info.map((item) => (
                  <li className="profile__main-info-item" key={item.id}>
                    <p className="profile__main-info-title">{item.field}</p>
                    <p className="profile__main-info-text">{item.value}</p>
                  </li>
                ))}
              </ul>

              {data.description ? (
                <p className="profile__text">{data.description}</p>
              ) : null}

              {data.music ? <Audio data={data.music} /> : null}
            </div>
          </div>

          {data.photos?.length > 0 ? <Gallery data={data.photos} /> : null}

          {data.interview?.length > 0 ? (
            <div className="profile__info-block">
              <ul className="profile__info-list">
                {data.interview.map((item) => (
                  <li className="profile__info-item" key={item.id}>
                    <div className="profile__interview-card">
                      <img
                        className="profile__avatar profile__avatar_size_small"
                        src={formatMediaPath(data.avatar, THUMB_IMG_FORMAT)}
                        alt={data.avatar?.alternativeText}
                      />
                      <p className="profile__interview-title">
                        {item.question}
                      </p>
                      <p className="profile__text profile__text_size_small">
                        {item.answer}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {data.interview_video || data.activities?.length > 0 ? (
            <div className="profile__info-block">
              <ul className="profile__info-list">
                {data.interview_video ? (
                  <li className="profile__info-item profile__info-item_type_video">
                    <Video data={data.interview_video} />
                  </li>
                ) : null}
                {data.activities?.map((item) => (
                  <li className="profile__info-item" key={item.id}>
                    <GigCard data={item} />
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {data.cta_array?.length > 0 ? (
            <ul className="profile__links-list">
              {data.cta_array.map((item) => (
                <li className="profile__link-item" key={item.id}>
                  {item.url ? (
                    <a
                      className="profile__link"
                      href={item.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.media ? (
                        <img
                          className="profile__link-icon"
                          src={formatMediaPath(item.media)}
                          alt={item.alternativeText}
                        />
                      ) : null}
                      <p className="profile__link-text">{item.title}</p>
                    </a>
                  ) : (
                    <div className="profile__link">
                      {item.media ? (
                        <img
                          className="profile__link-icon"
                          src={formatMediaPath(item.media)}
                          alt={item.alternativeText}
                        />
                      ) : null}
                      <p className="profile__link-text">{item.title}</p>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      ) : (
        <MiniPreloader isBig />
      )}
    </section>
  );
}

export default Profile;
