import moment from 'moment-timezone';
import './DateElement.css';

function DateElement({ type, date }) {
  return (
    <div className={`date-element ${type ? `date-element_type_${type}` : ''}`}>
      <p className="date-element__text">
        {moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format('MMMM D, YYYY')}
      </p>
    </div>
  );
}

export default DateElement;
