import RunnningLine from '../../RunningLine/RunningLine';
import img from '../../../assets/images/main/heading-img.webp';
import { BigLetters } from '../../../assets/icons/mainIcons';
import './HeadingMain.css';

const RUNNING_TEXT = ['PROMOTION', 'PROFESSIONAL', 'PRODUCTION'];

function HeadingMain() {
  return (
    <div className="main-heading">
      <div className="main-heading__container">
        <img className="main-heading__img" src={img} alt="" />
        <div className="main-heading__text-box">
        <h2 className="main-heading__subtitle">
            Творческая экосистема
            {/* , */}
            {/* <br />у которой есть душа
            <br />
            Мы меняем мир{' '}
            <span className="main-heading__subtitle_type_strikethrough">
              ивента
            </span> */}
          </h2>
          <h1 className="main-heading__title">
            {BigLetters({ mainClassName: 'main-heading__letters-icon' })}
            <br />
            <span className="main-heading__title_type_color">BRO</span> PRO
          </h1>
          <h2 className="main-heading__subtitle">
            {/* Творческая экосистема */}
            {/* , */}
            {/* <br />у которой есть душа
            <br /> */}
            Мы меняем мир{' '}
            <span className="main-heading__subtitle_type_strikethrough">
              ивента
            </span>
          </h2>
        </div>
      </div>

      <div className="main-heading__running-line">
        <RunnningLine>
          <ul className="main-heading__line">
            {RUNNING_TEXT.map((text) => (
              <li className="main-heading__line-item" key={text}>
                <p className="main-heading__line-text">{text}</p>
                <div className="main-heading__divider" />
              </li>
            ))}
          </ul>
        </RunnningLine>
      </div>
    </div>
  );
}

export default HeadingMain;
