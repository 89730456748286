import { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Header from '../Header/Header';
import Main from '../Main/Main';
import BigNews from '../BigNews/BigNews';
import Merch from '../Merch/Merch';
import Cart from '../Cart/Cart';
import Family from '../Family/Family';
import Connect from '../Connect/Connect';
import About from '../About/About';
import Footer from '../Footer/Footer';
import cartIcon from '../../assets/images/merch/cart.webp';
import './App.css';
import {
  ABOUT_LINK,
  CONTACT_LINK,
  FAMILY_LINK,
  GROWTH_LINK,
  MERCH_LINK,
  NEWS_LINK,
  OTHERS_LINK,
  POSTERS_LINK,
  PROFILE_LINK,
} from '../../assets/utils/constants';
import mainApi from '../../assets/api/MainApi';
import ArticleNews from '../Article/ArticleNews/ArticleNews';
import ArticleEvent from '../Article/ArticleEvent/ArticleEvent';
import Growth from '../Growth/Growth';
import Others from '../Others/Others';
import Profile from '../Profile/Profile';
import BroGigs from '../BroGigs/BroGigs';

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  const [cart, setCart] = useState([]);
  const [categories, setCategories] = useState(undefined);

  useEffect(() => {
    mainApi
      .getAllCategories({})
      .then((res) => setCategories(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let cartArray = JSON.parse(localStorage.getItem('cart'));
    if (!cartArray || cartArray.length === 0) {
      cartArray = [];
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
    } else {
      setCart(cartArray);
    }
  }, []);

  const getFinalPrice = (product, promo) => {
    const price_with_discount = product.discount
      ? Number(product.price) -
        (Number(product.price) / 100) * Number(product.discount)
      : Number(product.price);
    if (promo && promo.isValid) {
      return (
        price_with_discount -
        (price_with_discount / 100) * Number(promo.discount)
      );
    } else {
      return price_with_discount;
    }
  };

  function handleRemoveFromCart(item, isAll) {
    let cartArray = JSON.parse(localStorage.getItem('cart'));
    const item_to_delete = cartArray.filter((cart_item) => {
      if (
        cart_item.selected_size
          ? cart_item._id === item._id &&
            item.selected_size &&
            cart_item.selected_size &&
            cart_item.selected_size.name === item.selected_size.name
          : cart_item._id === item._id
      )
        return true;
      else return false;
    })[0];
    console.log(cartArray, item);

    if (isAll) {
      cartArray = cartArray.filter((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return false;
        else return true;
      });
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item
                  ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}`
                  : '',
                price: getFinalPrice(item),
                quantity: item_to_delete.count,
                variant: item.selected_size
                  ? item.selected_size.name
                  : 'Без размера',
              },
            ],
          },
        },
      });
      return;
    }

    if (
      cartArray &&
      cartArray.filter((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name &&
              cart_item.count > 1
            : cart_item._id === item._id && cart_item.count > 1
        )
          return true;
        else return false;
      }).length > 0
    ) {
      cartArray = cartArray.map((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name &&
              cart_item.count > 1
            : cart_item._id === item._id && cart_item.count > 1
        )
          return {
            ...cart_item,
            count: cart_item.count - 1,
          };
        else return cart_item;
      });
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /*window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          remove: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}` : '',
                price: getFinalPrice(item),
                quantity: 1,
                variant: item.selected_size ? item.selected_size.name : 'Без размера'
              },
            ],
          },
        }
      })*/
    } else {
      cartArray = cartArray.filter((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return false;
        else return true;
      });
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /*  window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          remove: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}` : '',
                price: getFinalPrice(item),
                quantity: 1,
                variant: item.selected_size ? item.selected_size.name : 'Без размера'
              },
            ],
          },
        }
      })*/
    }
  }

  function handleToCartBtn(item) {
    let cartArray = JSON.parse(localStorage.getItem('cart'));
    if (!cartArray || cartArray.length === 0) {
      cartArray = [
        {
          _id: item._id,
          name: item.name,
          selected_size: item.selected_size ? item.selected_size : null,
          count: 1,
        },
      ];
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /* window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}` : '',
                price: getFinalPrice(item),
                quantity: 1,
                variant: item.selected_size ? item.selected_size.name : 'Без размера'
              },
            ],
          },
        }
      })*/
    } else if (
      cartArray &&
      cartArray.filter((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return true;
        else return false;
      }).length > 0
    ) {
      cartArray = cartArray.map((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return {
            ...cart_item,
            count: cart_item.count + 1,
          };
        else return cart_item;
      });
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /*  window.dataLayer.push({
      })*/
    } else if (
      cartArray &&
      cartArray.filter((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return true;
        else return false;
      }).length > 0
    ) {
      cartArray = cartArray.map((cart_item) => {
        if (
          cart_item.selected_size
            ? cart_item._id === item._id &&
              item.selected_size &&
              cart_item.selected_size &&
              cart_item.selected_size.name === item.selected_size.name
            : cart_item._id === item._id
        )
          return {
            ...cart_item,
            count: cart_item.count + 1,
          };
        else return cart_item;
      });
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /*  window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}` : '',
                price: getFinalPrice(item),
                quantity: 1,
                variant: item.selected_size ? item.selected_size.name : 'Без размера'
              },
            ],
          },
        }
      })*/
    } else {
      cartArray = [
        ...cartArray,
        {
          _id: item._id,
          name: item.name,
          selected_size: item.selected_size ? item.selected_size : null,
          count: 1,
        },
      ];
      setCart(cartArray);
      localStorage.setItem('cart', JSON.stringify(cartArray));
      /*window.dataLayer.push({
      cartArray = [...cartArray, {
        _id: item._id,
        name: item.name,
        selected_size: item.selected_size ? item.selected_size : null,
        count: 1,
      }]
      setCart(cartArray)
      localStorage.setItem("cart", JSON.stringify(cartArray));
      {/*window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          add: {
            products: [
              {
                id: item?._id,
                name: `${item?.name} ${item?._id}`,
                category: item ? `Женская одежда/${item.category.title.ru}/${item.sub_category.title.ru}` : '',
                price: getFinalPrice(item),
                quantity: 1,
                variant: item.selected_size ? item.selected_size.name : 'Без размера'
              },
            ],
          },
        }
      })*/
    }
  }

  return (
    <div className="app">
      <Header />
      <div className="app__content">
        <Routes location={background || location}>
          <Route path="/" element={<Main {...{ categories }} />} />
          <Route path={`/${OTHERS_LINK}/*`} element={<Others />} />
          <Route path={`/${NEWS_LINK}`} element={<BigNews />} />
          <Route
            path={`/${NEWS_LINK}/:translit_name`}
            element={<ArticleNews />}
          />
          <Route
            path={`/${FAMILY_LINK}/*`}
            element={<Family {...{ categories }} />}
          />
          <Route path={`/${GROWTH_LINK}`} element={<Growth />} />
          {/* <Route path={`/${CONTACT_LINK}`} element={<Connect />} /> */}
          <Route path={`/${ABOUT_LINK}/*`} element={<About />} />
          <Route path={`/${POSTERS_LINK}`} element={<BroGigs />} />
          <Route
            path={`/${POSTERS_LINK}/:translit_name`}
            element={<ArticleEvent />}
          />
          <Route
            path={`/${PROFILE_LINK}/:category/:artist_id`}
            element={<Profile />}
          />
          {/* <Route
            path={`/${MERCH_LINK}`}
            element={
              <Merch
                cart={cart}
                handleRemoveFromCart={handleRemoveFromCart}
                handleToCartBtn={handleToCartBtn}
              />
            }
          /> */}
          {/* <Route
          path="/cart"
          element={
            <Cart
              cart={cart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleToCartBtn={handleToCartBtn}
              setCart={setCart}
            />
          }
        /> */}
        </Routes>
      </div>
      <Footer />

      {/* {location.pathname !== '/cart' ? (
        <Link
          className={`app__cart-container ${
            cart && cart.length > 0 ? 'app__cart-container_visible' : ''
          }`}
          to="/cart"
        >
          <p className="app__cart-count">{cart.length}</p>
          <img className="app__cart-img" src={cartIcon} alt=""></img>
        </Link>
      ) : null} */}
    </div>
  );
}

export default App;
