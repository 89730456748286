import { EyeIcon, LikeIcon } from '../../../../assets/icons/icons';
import { INSTA_PARSER_URL } from '../../../../assets/utils/constants';
import './InstagramCard.css';

function InstagramCard({ data }) {
  return (
    <div className="insta-card">
      <a
        className="insta-card__link"
        href={data.url}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="insta-card__img"
          src={`${INSTA_PARSER_URL}/get-file/${data.image}`}
          alt=""
        />

        <div className="insta-card__info">
          {data.views_count ? (
            <div className="insta-card__info-box">
              {EyeIcon({
                mainClassName: 'insta-card__info-icon',
                fillClassName: 'insta-card__info-icon-fill',
              })}
              <p className="insta-card__info-text">{data.views_count}</p>
            </div>
          ) : null}

          {/* {data.like_count ? (
            <div className="insta-card__info-box">
              {LikeIcon({
                mainClassName: 'insta-card__info-icon',
                fillClassName: 'insta-card__info-icon-fill',
              })}
              <p className="insta-card__info-text">{data.like_count}</p>
            </div>
          ) : null} */}
        </div>
      </a>
    </div>
  );
}

export default InstagramCard;
