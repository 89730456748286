import { useParams } from 'react-router-dom';
import PersonBox from '../../PersonBox/PersonBox';
import './FamilyList.css';
import { useEffect, useState } from 'react';
import mainApi from '../../../assets/api/MainApi';
import { FAMILY_LIMIT } from '../../../assets/utils/constants';
import MoreBtn from '../../MoreBtn/MoreBtn';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

const limit = FAMILY_LIMIT - 1;
let count = 0;

function FamilyList() {
  const { category_name } = useParams();
  const [artists, setArtists] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [isPreloader, setIsPreloader] = useState({
    main: true,
    more: false,
  });

  useEffect(() => {
    if (!category_name) return;

    getArtists({ start: 0, preloader: 'main' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_name]);

  function getArtists({ start, preloader }) {
    setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: true }));
    mainApi
      .getAllArtists({ start, limit: FAMILY_LIMIT, category: category_name })
      .then((res) => {
        const data = res.slice(0, limit);
        setArtists(start === 0 ? data : (prevArr) => prevArr.concat(data));
        setIsMore(res.length === FAMILY_LIMIT);
        count += 1;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: false }));
      });
  }

  function moreBtnClickHandler() {
    getArtists({ start: count * limit, preloader: 'more' });
  }

  return (
    <div className="family-list">
      {!isPreloader.main ? (
        <div className="family-list__container">
          {artists.length > 0 ? (
            <ul className="family-list__list">
              {artists.map((item) => (
                <li className="family-list__item" key={item.id}>
                  <PersonBox data={item} />
                </li>
              ))}
            </ul>
          ) : (
            <p className="family-list__text">Здесь пока никого нет</p>
          )}
          {isMore ? (
            <MoreBtn
              onClick={moreBtnClickHandler}
              isPreloader={isPreloader.more}
            />
          ) : null}
        </div>
      ) : (
        <MiniPreloader isBig />
      )}
    </div>
  );
}

export default FamilyList;
