import { MAIN_URL } from '../utils/constants';

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  getAllCategories({ start = 0, limit }) {
    let params = {};
    params.start = start;
    if (limit) params.limit = limit;

    return fetch(
      `${this._BASE_URL}/categories/no-auth/getAll?` +
        new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getAllArtists({ start = 0, limit, category }) {
    let params = {};
    params.start = start;
    if (limit) params.limit = limit;
    if (category) params.category = category;

    return fetch(
      `${this._BASE_URL}/artists/no-auth/getAll?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getArtistById({ category, artist_id }) {
    return fetch(
      `${this._BASE_URL}/artists/no-auth/${category}/get-by-id/${artist_id}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getAllNews({ start = 0, limit }) {
    let params = {};
    params.start = start;
    if (limit) params.limit = limit;

    return fetch(
      `${this._BASE_URL}/announces/no-auth/getAll?` +
        new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getNewsByName({ translit_name }) {
    return fetch(
      `${this._BASE_URL}/announces/no-auth/get-by-name/${translit_name}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getAllGigs({ start = 0, limit, isActive, date, isLegendary }) {
    let params = {};
    params.start = start;
    if (limit) params.limit = limit;
    if (date) params.date = date;
    if (isActive !== undefined) params.isActive = isActive;
    if (isLegendary !== undefined) params.isLegendary = isLegendary;

    return fetch(
      `${this._BASE_URL}/posters/no-auth/get-by-date/?` +
        new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }

  getGigByName({ translit_name }) {
    return fetch(
      `${this._BASE_URL}/posters/no-auth/get-by-name/${translit_name}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: `${MAIN_URL}/api`,
});

export default mainApi;
