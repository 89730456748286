import { Slider } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import {
  FastForward,
  FastRewind,
  Pause,
  PlayArrow,
  VolumeUp,
  VolumeOff,
  CropFree,
} from '@mui/icons-material';
import './Control.css';

const useStyles = makeStyles({
  volumeSlider: {
    color: 'var(--about-yellow-accent-color) !important',
  },
});

const PrettoSlider = withStyles({
  root: {
    color: 'var(--about-yellow-accent-color) !important',
    boxSizing: 'border-box !important',
  },
})(Slider);

function Control({
  controlRef,
  title,
  playing,
  played,
  volume,
  mute,
  buffer,
  duration,
  currentTime,
  onPlayPause,
  onRewind,
  onForward,
  onSeek,
  onSeekMouseUp,
  onSeekMouseDown,
  onVolumeChange,
  onVolumeSeekUp,
  onMute,
  isScreenfulEnabled,
  onToggleFullScreen,
}) {
  const classes = useStyles();

  return (
    <div className="control" ref={controlRef}>
      <div className="control__container">
        <p className="control__title">{title}</p>
      </div>

      {!buffer ? (
        <div className="control__container control__container_position_center">
          <button
            className="control__icon-btn"
            type="button"
            onClick={onRewind}
          >
            <FastRewind fontSize="medium" />
          </button>
          <button
            className="control__icon-btn"
            type="button"
            onClick={onPlayPause}
          >
            {playing ? (
              <Pause fontSize="medium" />
            ) : (
              <PlayArrow fontSize="medium" />
            )}{' '}
          </button>
          <button
            className="control__icon-btn"
            type="button"
            onClick={onForward}
          >
            <FastForward fontSize="medium" />
          </button>
        </div>
      ) : (
        <p className="control__text">Loading...</p>
      )}

      <div className="control__container">
        <div className="control__slider-container">
          <PrettoSlider
            min={0}
            max={100}
            value={played * 100}
            onChange={onSeek}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onSeekMouseDown}
          />
        </div>

        <div className="control__control-box">
          <div className="control__controls">
            <button
              className="control__icon-btn"
              type="button"
              onClick={onPlayPause}
            >
              {playing ? (
                <Pause fontSize="small" />
              ) : (
                <PlayArrow fontSize="small" />
              )}
            </button>

            <button
              className="control__icon-btn"
              type="button"
              onClick={onMute}
            >
              {mute ? (
                <VolumeOff fontSize="small" />
              ) : (
                <VolumeUp fontSize="small" />
              )}
            </button>

            <div className="control__slider-container control__slider-container_size_small">
              <Slider
                className={`${classes.volumeSlider}`}
                value={volume * 100}
                onChange={onVolumeChange}
                onChangeCommitted={onVolumeSeekUp}
              />
            </div>
          </div>

          <div className="control__controls control__controls_type_right">
            <p className="control__text control__text_type_control">
              {currentTime} / {duration}
            </p>
            {isScreenfulEnabled ? (
              <button
                className="control__icon-btn"
                type="button"
                onClick={onToggleFullScreen}
              >
                <CropFree fontSize="small" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Control;
