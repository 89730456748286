import { Link, useLocation } from 'react-router-dom';
import { LogoIcon } from '../../assets/icons/icons';
import banner from '../../assets/images/footer/banner.webp';
import './Footer.css';
import {
  ABOUT_EVENTS_LINK,
  CONTACTS_LIST,
  NAV_LINKS_LIST,
  NEWS_LINK,
} from '../../assets/utils/constants';

function Footer() {
  const { pathname } = useLocation();
  const isNewsArticle =
    pathname.includes(NEWS_LINK) && !pathname.endsWith(NEWS_LINK);
  const isEventArticle =
    pathname.includes(ABOUT_EVENTS_LINK) &&
    !pathname.endsWith(ABOUT_EVENTS_LINK);

  return (
    <footer
      className={`footer ${isNewsArticle || isEventArticle ? 'footer_hidden' : ''
        }`}
    >
      <div className="footer__container">
        <img className="footer__img" src={banner} alt="" />

        <div className="footer__content">
          <Link className="footer__main-link" to="/">
            {LogoIcon({
              mainClassName: 'footer__logo-icon',
              fillClassName: 'footer__logo-icon-fill',
              secondaryFillClassName: 'footer__logo-icon-color-fill',
            })}
          </Link>
          <p className="footer__slogan">
            Наше творчество{" "}
            <span className="footer__slogan_type_color">— дорогое удовольствие</span>
          </p>

          <ul className="footer__links-list">
            {NAV_LINKS_LIST.map((item) => (
              <li className="footer__link-item" key={item.link}>
                <Link
                  className={`footer__link ${item.isDisabled ? 'footer__link_disabled' : ''
                    }`}
                  to={`/${item.link}`}
                >
                  <p className="footer__link-text">{item.label}</p>
                </Link>
              </li>
            ))}
            {CONTACTS_LIST.map((item, i) => (
              <li className="footer__link-item" key={i}>
                {item.href ? (
                  <a
                    className={`footer__link ${item.isDisabled ? 'footer__link_disabled' : ''
                      }`}
                    href={item.href}
                  >
                    <p className="footer__link-text">{item.label}</p>
                  </a>
                ) : item.link ? (
                  <Link
                    className={`footer__link ${item.isDisabled ? 'footer__link_disabled' : ''
                      }`}
                    to={item.link}
                  >
                    <p className="footer__link-text footer__link-text_type_color">
                      {item.label}
                    </p>
                  </Link>
                ) : (
                  <p className="footer__link-text">{item.label}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
