import img from '../images/merch/card1.webp';
import img2 from '../images/merch/card2.webp';
import img3 from '../images/merch/card3.webp';
import img4 from '../images/merch/card4.webp';
import img5 from '../images/merch/card5.webp';

import telegram from '../icons/telegram-icon.webp';
// import whatsapp from '../icons/whatsapp-icon.webp';
import instagram from '../icons/instagram-icon.webp';
// import facebook from '../icons/facebook-icon.webp';

import djImg from '../images/main/dj-img.webp';
import contentImg from '../images/main/content-img.webp';
import showImg from '../images/main/show-img.webp';

import producersLogo from '../images/others/producers/icon.webp';
import producers1 from '../images/others/producers/card1.webp';
import producers2 from '../images/others/producers/card2.webp';
import producers3 from '../images/others/producers/card3.webp';
import producers4 from '../images/others/producers/card4.webp';

import designLogo from '../images/others/design/icon.webp';
import design1 from '../images/others/design/card1.webp';
import design2 from '../images/others/design/card2.webp';
import design3 from '../images/others/design/card3.webp';
import design4 from '../images/others/design/card4.webp';

export const MAIN_URL = 'https://strapi-articles.bigbropro.ru';
export const MEDIA_URL = `${MAIN_URL}/uploads`;
export const INSTA_PARSER_URL = 'https://inst-parser.bigbropro.ru';

export const LARGE_IMG_FORMAT = 'large';
export const MEDIUM_IMG_FORMAT = 'medium';
export const SMALL_IMG_FORMAT = 'small';
export const THUMB_IMG_FORMAT = 'thumbnail';

export const ABOUT_LINK = 'about';
export const ABOUT_WE_DO_LINK = 'what-we-do';
export const ABOUT_WHY_US_LINK = 'why-us';
export const ABOUT_EVENTS_LINK = 'events';

export const OTHERS_LINK = 'others';
export const OTHERS_PRODUCERS_LINK = 'producers';
export const OTHERS_EVENTS_LINK = 'events';
export const OTHERS_TECH_LINK = 'technologies';
export const OTHERS_DESIGN_LINK = 'design';

export const FAMILY_LINK = 'family';
export const MERCH_LINK = 'merch';
export const GROWTH_LINK = 'growth';
export const CONTACT_LINK = 'contact';
export const NEWS_LINK = 'feed';
export const PROFILE_LINK = 'profile';
export const POSTERS_LINK = 'posters';

export const CONNECT_PHONE = {
  label: '+7 916 900-46-62',
  value: '+79169004662',
};

export const CONNECT_PHONE_PM = {
  label: '+7 999 982-64-09',
  value: '+79999826409',
};

export const INSTA_LINK = 'https://www.instagram.com/big.bro.pro/';
export const TELEGRAM_LINK = 'https://t.me/LSV_SRG';

export const NAV_LINKS_LIST = [
  {
    label: 'ЭТО МЫ',
    link: ABOUT_LINK,
    // text: 'New Event',
  },
  {
    label: 'СЕМЬЯ',
    link: FAMILY_LINK,
  },
  // {
  //   label: 'МЕРЧ',
  //   link: MERCH_LINK,
  //   // text: '+12',
  //   isDisabled: true,
  // },
  {
    label: 'РОСТ',
    link: GROWTH_LINK,
  },
  {
    label: 'ЗАОДНО',
    link: OTHERS_LINK,
  },
  // {
  //   label: 'СВЯЗЬ',
  //   link: CONTACT_LINK,
  //   isDisabled: true,
  // },
];

export const SOCIAL_LINKS_LIST = [
  // {
  //   icon: facebook,
  //   href: '#',
  // },
  {
    icon: instagram,
    href: INSTA_LINK,
  },
  {
    icon: telegram,
    href: TELEGRAM_LINK,
  },
];

export const CONTACTS_LIST = [
  {
    label: CONNECT_PHONE.label,
    href: `tel:${CONNECT_PHONE.value}`,
  },
  {
    label: CONNECT_PHONE_PM.label,
    href: `tel:${CONNECT_PHONE_PM.value}`,
  },
  // {
  //   label: 'Our social networks',
  //   link: `/${CONTACT_LINK}`,
  //   isDisabled: true,
  // },
];

export const MAIN_CARDS_LIST = [
  {
    title: 'DJ',
    img: djImg,
  },
  {
    title: 'Show',
    img: showImg,
  },
  {
    title: 'Content Makers',
    img: contentImg,
  },
];

export const ABOUT_NAV_LIST = [
  {
    label: 'Наш BIG BRO',
    link: '',
  },
  {
    label: 'Что мы делаем?',
    link: ABOUT_WE_DO_LINK,
  },
  {
    label: 'Почему BIG BRO PRO?',
    link: ABOUT_WHY_US_LINK,
  },
  {
    label: 'Легендарные ивенты',
    link: ABOUT_EVENTS_LINK,
  },
];

export const MAIN_INFO_LIMIT = 3;
export const GIGS_LIMIT = 4;
export const NEWS_LIMIT = 10;
export const FAMILY_LIMIT = 9;
export const INSTA_LIMIT = 6;

export const HEADING_BLOCK = 'heading';
export const PARAGRAPH_BLOCK = 'paragraph';
export const LIST_BLOCK = 'list';
export const ORDERED_LIST_FORMAT = 'ordered';
export const IMAGE_BLOCK = 'image';
export const QUOTE_BLOCK = 'quote';
export const CODE_BLOCK = 'code';
export const CHILD_TYPE_TEXT = 'text';
export const CHILD_TYPE_LINK = 'link';
export const CHILD_TYPE_LIST_ITEM = 'list-item';

export const EVENT_TYPE = 'event';
export const NEWS_TYPE = 'news';

export const GROWTH_INFO_LIST = [
  {
    label: 'Точка Пи',
    title: 'DJ Сергей Лосев — Дубай, Wedding Awards, BIG BRO PRO',
    text: 'Интервью для канала «Точка Пи» на тему Дубая, Wedding Awards, BIG BRO PRO, гонорара и много другого. Самое полное интервью на данный момент.',
    link: 'https://www.youtube.com/embed/xJeXK0otcjA?si=6ojToUw8CoIeBBWO',
  },
  {
    label: 'ARTE',
    title:
      'Kein Krieg, keine Sanktionen, keine Probleme: Shopping-Reisen nach Dubai',
    text: 'Интервью для франко-немецкого канала Arte на тему «Шоппинг в Дубае как феномен современности». Знали бы вы, в каком шоке европейцы от того, что русские ездят в Дубай чисто для того, чтобы закупиться вещами :)',
    link: 'https://www.youtube.com/embed/5bKzMlo93xk?si=vBLhvxmmd1LoYcPe',
  },
  {
    label: 'POGY',
    title: 'Сергей Лосев — больше, чем диджей',
    text: 'POGY подкаст с говорящим названием «Больше, чем диджей» на канале Артура Погосяна. О том, как Сергей перевернул правила игры, о человечности в работе, целях на будущее и не только.',
    link: 'https://www.youtube.com/embed/FJjojnRXf_c?si=EOsvSfM5yTB_Hx6D',
  },
];

export const OTHERS_LIST = [
  // {
  //   isDisabled: true,
  //   label: 'Продюсерский центр',
  //   link: OTHERS_PRODUCERS_LINK,
  // },
  {
    label: 'Организация мероприятий',
    link: OTHERS_EVENTS_LINK,
    icon: producersLogo,
    source: { link: `tel:${CONNECT_PHONE.value}`, label: 'Контакты' },
    text: 'Мы выступаем в роли организатора событий, имея за плечами более 10-летний опыт. Агентство «СЛУЖБА СОБЫТИЙ». Агентство «STAYA». Слышали? Маловероятно! Но не потому, о чём вы подумали. Мы никогда не афишировали своё название и работали только с теми клиентами, запрос которых сильно отличался от большинства. Уникальные концепции, авторские квесты, смыслы и глубина — вот что выделяет наш творческий подход.',
    title: 'События',
    subtitle:
      'Корпоративные или частные мероприятия, презентации, клубные вечеринки и не только!',
    imgs: [producers1, producers2, producers3, producers4],
  },
  // {
  //   isDisabled: true,
  //   label: 'Техническое обеспечение',
  //   link: OTHERS_TECH_LINK,
  // },
  {
    label: 'Дизайн-бюро',
    link: OTHERS_DESIGN_LINK,
    icon: designLogo,
    source: { link: 'https://cabahub.com/', label: 'cabahub.com' },
    text: 'Ещё до личного знакомства с нашим партнёром Caba Hub мы точно знали, что разработкой нашего сайта будут заниматься они и никто другой. Мы с гордостью заявляем об этом и предлагаем всем испытать чистый кайф от работы с их командой.',
    title: 'Ваш фулл-сервис в сфере айти',
    subtitle:
      'Разработка сайтов, приложений, бренд-девелопмент, ребрендинг, кибербезопасность, техническая поддержка и консультации.',
    imgs: [design1, design2, design3, design4],
  },
];

export const CONNECT_PHONE_FIRST = '+9 827 647-63-43';
export const CONNECT_PHONE_SECOND = '+9 827 647-63-43';

export const PRODUCTS = [
  {
    _id: '1',
    name: `Футболка
  и Сумка`,
    price: '3200',
    description: `Lorem ipsum dolor sit amet consectetur. Erat senectus donec neque odio ultrices aenean vulputate quis. Molestie faucibus volutpat aliquet.`,
    product_img: img,
    categorie: ['Аксессуары', 'Одежда'],
  },
  {
    _id: '2',
    name: `Браслет
  BIGBROPRO`,
    price: '1200',
    description: `Lorem ipsum dolor sit amet consectetur. Erat senectus donec neque odio ultrices aenean vulputate quis. Molestie faucibus volutpat aliquet.`,
    product_img: img2,
    categorie: ['Аксессуары'],
  },
  {
    _id: '3',
    name: `Скооотч
  BIGBROPRO`,
    discount: '15',
    price: '900',
    description: `Lorem ipsum dolor sit amet consectetur. Erat senectus donec neque odio ultrices.`,
    product_img: img3,
    categorie: ['Аксессуары'],
  },
  {
    _id: '4',
    name: `Футболка
  BIGBROPRO`,
    price: '2500',
    description: `Lorem ipsum dolor sit amet consectetur. Erat senectus donec neque odio ultrices.`,
    product_img: img4,
    categorie: ['Аксессуары'],
  },
  {
    _id: '5',
    name: `Футболка
  BIGBROPRO`,
    price: '2500',
    description: `Lorem ipsum dolor sit amet consectetur. Erat senectus donec neque odio ultrices.`,
    product_img: img5,
    categorie: ['Аксессуары'],
  },
];
