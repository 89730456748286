import bigbro from '../../../assets/images/us/bigbro.webp';
import './BigBro.css';

function BigBro() {
  return (
    <section className="about-big-bro">
      <figure className="about-big-bro__figure">
        <img className="about-big-bro__img" src={bigbro} alt="" />
        <figcaption className="about-big-bro__figcaption">
          <h3 className="about-big-bro__caption-title">Сергей Лосев</h3>
          <p className="about-big-bro__caption-text">
            Диджей, продюсер, генератор. Основатель продюсерского центра BIG BRO
            PRO.
          </p>
        </figcaption>
      </figure>

      <div className="about-big-bro__content">
        <ul className="about-big-bro__article">
          <li className="about-big-bro__list-item">
            <p className="about-big-bro__text">
              Родился в Екатеринбурге. С самого детства был активным и
              разносторонним: танцы, футбол, волейбол, первое место на городской
              олимпиаде по черчению и ещё море всего. Окончил лицей искусств, а
              дальше сердце выбрало цифры. Итог — диплом с отличием из колледжа
              по «Бухгалтерскому учёту, анализу и аудиту» и диплом из вуза по
              «Экономике и управлению на предприятии». Неплохо, но Сергею снова
              мало.
            </p>
          </li>
          <li className="about-big-bro__list-item">
            <p className="about-big-bro__text">
              <span className="about-big-bro__text_weight_bold">
                Новая глава в жизни — DJ.
              </span>{' '}
              Окончив диджей-школу, Сергей стал уделять всё больше времени
              своему хобби. А параллельно в течение 10 лет развивался в сфере
              продаж — от ассистента менеджера до руководителя отдела по
              развитию бизнеса сразу в 4 сферах: продаж, логистики, закупок и
              партнёрства.
            </p>
          </li>
          <li className="about-big-bro__list-item">
            <p className="about-big-bro__text">
              В 2012 году Сергей решил полностью посвятить себя ивент-индустрии
              — так родилось агентство по организации мероприятий  «Служба
              Событий», а в 2021 году — проект{' '}
              <span className="about-big-bro__text_weight_bold">
                BIG BRO PRO
              </span>
              . Профессиональный подход Сергея — понять на своём опыте, как
              работают все сферы ивент-индустрии, и лишь потом создавать что-то
              по-настоящему крутое.
            </p>
          </li>
          <li className="about-big-bro__list-item">
            <p className="about-big-bro__text">
              <span className="about-big-bro__text_weight_bold">
                «Аниматор, инструктор командообразования, хостесс, ведущий,
                сценарист, режиссер — это лишь часть, в роли кого я пробовал
                себя. Прежде, чем создавать агентство и встать у руля, мне было
                интересно узнать все процессы изнутри»
              </span>
              , — делится Сергей.
            </p>
          </li>
        </ul>

        <div className="about-big-bro__info">
          <p className="about-big-bro__subtitle">Ещё парочка достижений:</p>
          <ul className="about-big-bro__info-list">
            <li className="about-big-bro__list-item">
              <p className="about-big-bro__text about-big-bro__text_type_info about-big-bro__text_weight_medium">
                Резидент клубных проектов Мята, Look&nbsp;In, Скромное Обаяние
                Буржуазии, White&nbsp;Rabbit, StereoPeople, Техника
                Безопасности, Вокруг Света, Rouge
              </p>
            </li>
            <li className="about-big-bro__list-item">
              <p className="about-big-bro__text about-big-bro__text_type_info about-big-bro__text_weight_medium">
                Сооснователь проекта TopEventDJ. Позже — участник проекта
                Top15Moscow
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default BigBro;
