import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import './Gallery.css';
import { SwiperArrowIcon } from '../../../assets/icons/icons';
import { MEDIUM_IMG_FORMAT } from '../../../assets/utils/constants';
import { formatMediaPath } from '../../../assets/utils/utils';

const SLIDES_PER_VIEW = 6;

function Gallery({ data }) {
  const swiperRef = useRef(null);

  return (
    <div className="gallery">
      <button
        className={`gallery__nav-btn gallery__nav-btn_type_prev ${
          data.length <= SLIDES_PER_VIEW ? 'gallery__nav-btn_disabled' : ''
        }`}
        type="button"
        id="previousButton"
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        {SwiperArrowIcon({
          mainClassName: 'gallery__nav-icon gallery__nav-icon_type_prev',
          fillClassName: 'gallery__nav-icon-fill',
        })}
      </button>
      <div className="gallery__swiper-box">
        <Swiper
          className="gallery__swiper"
          ref={swiperRef}
          slidesPerView={3.8}
          spaceBetween={2}
          modules={[Mousewheel]}
          grabCursor={true}
          mousewheel={{ forceToAxis: true }}
          preventInteractionOnTransition={true}
          centeredSlides={false}
          loop={true}
          breakpoints={{
            901: {
              slidesPerView: SLIDES_PER_VIEW,
              spaceBetween: 4,
            },
          }}
        >
          {data.map((item) => (
            <SwiperSlide className="gallery__slide" key={item.id}>
              <img
                className="gallery__img"
                src={formatMediaPath(item.photo, MEDIUM_IMG_FORMAT)}
                alt={item.photo?.alternativeText}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <button
        className={`gallery__nav-btn ${
          data.length <= SLIDES_PER_VIEW ? 'gallery__nav-btn_disabled' : ''
        }`}
        type="button"
        id="nextButton"
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        {SwiperArrowIcon({
          mainClassName: 'gallery__nav-icon',
          fillClassName: 'gallery__nav-icon-fill',
        })}
      </button>
    </div>
  );
}

export default Gallery;
