import { Link, NavLink } from 'react-router-dom';
import usePreventScroll from '../../../assets/hooks/usePreventScroll';
import './MobileMenu.css';
import { CONTACTS_LIST, NAV_LINKS_LIST } from '../../../assets/utils/constants';

function MobileMenu({ isOpen, onClose }) {
  usePreventScroll(isOpen);

  return (
    <div
      className={`header-mobile ${isOpen ? 'header-mobile_opened' : ''}`}
      onClick={onClose}
    >
      <div
        className="header-mobile__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="header-mobile__content">
          <nav className="header-mobile__nav">
            <ul className="header-mobile__nav-list">
              {NAV_LINKS_LIST.map((item) => (
                <li className="header-mobile__item" key={item.link}>
                  <NavLink
                    className={({ isActive }) =>
                      'header-mobile__link' +
                      (item.isDisabled ? ' header-mobile__link_disabled' : '') +
                      (isActive ? ' header-mobile__link_selected' : '')
                    }
                    to={`/${item.link}`}
                    onClick={onClose}
                  >
                    <p className="header-mobile__link-text header-mobile__link-text_type_nav">
                      {item.label}
                    </p>
                    {item.text ? (
                      <p className="header-mobile__link-note">{item.text}</p>
                    ) : null}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          <ul className="header-mobile__nav-list header-mobile__nav-list_type_contacts">
            {CONTACTS_LIST.map((item, i) => (
              <li className="header-mobile__item" key={i}>
                {item.href ? (
                  <a className="header-mobile__link" href={item.href}>
                    <p className="header-mobile__link-text">{item.label}</p>
                  </a>
                ) : item.link ? (
                  <Link className="header-mobile__link" to={item.link}>
                    <p className="header-mobile__link-text header-mobile__link-text_type_color">
                      {item.label}
                    </p>
                  </Link>
                ) : (
                  <p className="header-mobile__link-text">{item.label}</p>
                )}
              </li>
            ))}
          </ul>

          <p className="header-mobile__copyright">
             © Все права защищены. BIG BRO PRO - 2024
          </p>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
