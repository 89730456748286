import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import './GigCard.css';
import { POSTERS_LINK } from '../../../../assets/utils/constants';
import { formatMediaPath } from '../../../../assets/utils/utils';

function GigCard({ data, isMonochrome, isGrid }) {
  return (
    <div className={`gig-card ${isGrid ? 'gig-card_type_grid' : ''}`}>
      <Link
        className="gig-card__link"
        to={`/${POSTERS_LINK}/${data.translit_name}`}
      >
        <img
          className={`gig-card__img ${
            isMonochrome ? 'gig-card__img_type_monochrome' : ''
          }`}
          src={formatMediaPath(data.title_photo)}
          alt={data.title_photo?.alternativeText}
        />
        <div className="gig-card__text-box">
          <p className="gig-card__date">
            {moment(data.date, 'YYYY-MM-DDTHH:mm:ss').format('MMMM D, HH:mm')}
          </p>
          <p className="gig-card__title">{data.title}</p>
        </div>
      </Link>
    </div>
  );
}

export default GigCard;
