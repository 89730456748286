import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import PersonBox from '../../PersonBox/PersonBox';
import { SwiperArrowIcon } from '../../../assets/icons/icons';
import bg from '../../../assets/images/main/family-bg.webp';
import img from '../../../assets/images/main/family-img.webp';
import './FamilyMain.css';
import 'swiper/css';
import 'swiper/css/navigation';
import mainApi from '../../../assets/api/MainApi';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function FamilyMain() {
  const swiperRef = useRef(null);
  const [artists, setArtists] = useState([]);
  const [isPreloader, setIsPreloader] = useState(true);

  useEffect(() => {
    setIsPreloader(true);
    mainApi
      .getAllArtists({})
      .then((res) => setArtists(res))
      .catch((err) => console.log(err))
      .finally(() => setIsPreloader(false));
  }, []);

  return (
    <div className="main-family">
      <div className="main-family__container">
        <img className="main-family__heading-img" src={img} alt="" />

        <div className="main-family__swiper-container">
          <button
            className="main-family__nav-btn"
            type="button"
            id="previousButton"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            {SwiperArrowIcon({
              mainClassName:
                'main-family__nav-icon main-family__nav-icon_type_prev',
              fillClassName: 'main-family__nav-icon-fill',
            })}
          </button>
          <div className="main-family__swiper-box">
            {!isPreloader ? (
              <Swiper
                className="main-family__swiper"
                ref={swiperRef}
                slidesPerView={2.3}
                spaceBetween={20}
                modules={[Mousewheel]}
                grabCursor={true}
                mousewheel={{ forceToAxis: true }}
                preventInteractionOnTransition={true}
                centeredSlides={false}
                loop={true}
                breakpoints={{
                  1300: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  901: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  550: {
                    slidesPerView: 2.8,
                    spaceBetween: 20,
                  },
                }}
              >
                {artists.map((data) => (
                  <SwiperSlide className="main-family__slide" key={data.id}>
                    <PersonBox type="swiper" {...{ data }} />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="main-family__preloader">
                <MiniPreloader />
              </div>
            )}
          </div>
          <button
            className="main-family__nav-btn"
            type="button"
            id="nextButton"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            {SwiperArrowIcon({
              mainClassName: 'main-family__nav-icon',
              fillClassName: 'main-family__nav-icon-fill',
            })}
          </button>
        </div>

        <p className="main-family__text">
          Вся творческая семья в сборе: диджеи, артисты, шоу&#8209;кейсы,
          рилс&#8209;мейкеры и не только. Добро&nbsp;пожаловать к нам в гости!
        </p>
      </div>

      <img className="main-family__bg" src={bg} alt="" />
    </div>
  );
}

export default FamilyMain;
