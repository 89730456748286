import { EVENT_TYPE, LARGE_IMG_FORMAT } from '../../../../assets/utils/constants';
import { formatMediaPath } from '../../../../assets/utils/utils';
import DateElement from '../DateElement/DateElement';
import TicketsElement from '../TicketsElement/TicketsElement';
import './Poster.css';

function Poster({ data }) {
  return (
    <div className="poster">
      <img
        className="poster__img"
        src={formatMediaPath(data.title_photo, LARGE_IMG_FORMAT)}
        alt={data.title_photo?.alternativeText}
      />

      <div className="poster__content">
        <DateElement date={data.date} type={EVENT_TYPE} />
        <p className="poster__title">{data.title}</p>
        {data.description ? (
          <p className="poster__text text-element__text">{data.description}</p>
        ) : null}
        {data.tickets_url ? (
          <div className="poster__link">
            <TicketsElement tickets_url={data.tickets_url} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Poster;
