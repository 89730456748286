import { NavLink } from 'react-router-dom';
import './PageNav.css';

function PageNav({ list }) {
  return (
    <nav className="page-nav">
      <ul className="page-nav__list">
        {list.map((item) => (
          <li className="page-nav__item" key={item.label}>
            <NavLink
              className={({ isActive }) =>
                'page-nav__link-btn' +
                (item.isDisabled ? ' page-nav__link-btn_disabled' : '') +
                (isActive ? ' page-nav__link-btn_selected' : '')
              }
              to={item.link}
              end
            >
              <p className="page-nav__link-text">{item.label}</p>
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PageNav;
