import { Navigate, Route, Routes } from 'react-router-dom';
import banner from '../../assets/images/others/banner.webp';
import bannerMobile from '../../assets/images/others/banner-mobile.webp';
import './Others.css';
import PageNav from '../PageNav/PageNav';
import {
  OTHERS_EVENTS_LINK,
  OTHERS_LINK,
  OTHERS_LIST,
} from '../../assets/utils/constants';
import OthersContent from './OthersContent/OthersContent';

function Others() {
  return (
    <div className="others">
      <img className="others__banner" src={banner} alt="Заодно" />
      <img
        className="others__banner others__banner_type_mobile"
        src={bannerMobile}
        alt="Заодно"
      />
      <div className="others__nav">
        <PageNav list={OTHERS_LIST} />
      </div>

      <div className="others__content">
        <Routes>
          <Route
            index
            element={<Navigate to={`/${OTHERS_LINK}/${OTHERS_EVENTS_LINK}`} />}
          />
          {OTHERS_LIST.map((item) =>
            !item.isDisabled ? (
              <Route
                path={`/${item.link}`}
                element={<OthersContent data={item} />}
                key={item.label}
              />
            ) : null
          )}
        </Routes>
      </div>
    </div>
  );
}

export default Others;
