export const BigLetters = ({ mainClassName }) => {
  return (
    <svg
      className={mainClassName}
      xmlns="http://www.w3.org/2000/svg"
      width="442"
      height="134"
      viewBox="0 0 442 134"
      fill="none"
    >
      <g filter="url(#filter0_d_8_1360)">
        <path
          d="M0 125.727H69.1137C111.202 125.727 144.208 121.355 144.208 92.3279C144.208 66.4481 118.069 65.2241 94.3668 64.5246V57.1803C110.981 55.9563 132.689 55.2568 132.689 32.1749C132.689 8.39345 111.202 2.44809 67.5631 2.44809H0V125.727ZM40.5379 98.6229V73.9672H71.3289C91.2655 73.9672 103.449 73.2678 103.449 86.2076C103.449 99.1475 91.9301 98.6229 71.3289 98.6229H40.5379ZM40.5379 49.1366V27.9781H71.5504C84.8415 27.9781 93.7023 27.8033 93.7023 38.6448C93.7023 49.8361 83.9554 49.1366 71.5504 49.1366H40.5379Z"
          fill="var(--text-color)"
        />
        <path
          d="M156.764 2.44809V30.2514H192.428V97.7486H156.764V125.727H268.188V97.7486H232.523V30.2514H268.188V2.44809H156.764Z"
          fill="var(--text-color)"
        />
        <path
          d="M351.621 128C398.804 128 400.576 100.546 399.469 95.1257H409.658L401.905 117.333V125.727H442V72.5683C442 61.9016 434.025 55.9563 420.956 55.9563H363.583V80.2623H401.241C399.69 92.3279 385.07 100.197 362.032 100.197C331.905 100.197 318.614 86.3825 318.614 64.5246C318.614 41.2678 333.899 27.4536 359.817 27.4536C377.76 27.4536 392.38 34.9727 395.038 46.5137H436.462C431.146 18.7104 399.025 0 357.602 0C308.868 0 276.969 24.8306 276.969 65.0492C276.969 102.995 305.545 128 351.621 128Z"
          fill="var(--text-color)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8_1360"
          x="0"
          y="0"
          width="442"
          height="134"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.658817 0 0 0 0 0.615625 0 0 0 0 0.75 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8_1360"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8_1360"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
