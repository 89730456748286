import {
  CHILD_TYPE_LINK,
  CHILD_TYPE_LIST_ITEM,
  CHILD_TYPE_TEXT,
  MEDIA_URL,
} from './constants';

export function renderArticleChildren(children) {
  return children.map((child, idx) => {
    let childElement = null;

    // Обработка обычного текста
    if (child.type === CHILD_TYPE_TEXT) {
      childElement = (
        <span
          className={`${child.bold ? 'bold' : ''}${
            child.italic ? 'italic' : ''
          }${child.underline ? 'underline' : ''}${
            child.strikethrough ? 'strikethrough' : ''
          }`}
          key={idx}
        >
          {child.text}
        </span>
      );
    }

    // Обработка ссылок
    if (child.type === CHILD_TYPE_LINK && child.children) {
      childElement = (
        <a
          className={`link ${child.bold ? 'bold' : ''}${
            child.italic ? 'italic' : ''
          }${child.underline ? 'underline' : ''}${
            child.strikethrough ? 'strikethrough' : ''
          }`}
          key={idx}
          href={child.url}
          target="_blank"
          rel="noreferrer"
        >
          {renderArticleChildren(child.children)}
        </a>
      );
    }

    // Обработка элементов списка
    if (child.type === CHILD_TYPE_LIST_ITEM && child.children) {
      childElement = renderArticleChildren(child.children);
    }

    return childElement;
  });
}

export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return '00:00';
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, '0')} `;
  } else return `${minutes}:${seconds}`;
};

export const formatMediaPath = (media, size) => {
  if (!media) return;

  const { hash, ext, formats } = media;
  return formats && formats[size]
    ? `${MEDIA_URL}/${formats[size].hash}${formats[size].ext}`
    : `${MEDIA_URL}/${hash}${ext}`;
};

export function getRandomNumber(multiplier) {
  return Math.floor(Math.random() * multiplier);
}

export const sendMetriс = (type, value) => {
  window.ym(94194405, type, value);
};

export const getFinalPrice = (product, promo) => {
  const price_with_discount = product.discount
    ? Number(product.price) -
      (Number(product.price) / 100) * Number(product.discount)
    : Number(product.price);
  if (promo && promo.isValid) {
    return (
      price_with_discount - (price_with_discount / 100) * Number(promo.discount)
    );
  } else {
    return price_with_discount;
  }
};

export function getTotal(cart, promo) {
  if (cart && cart.length > 0) {
    let total_price = 0;
    let total_price_without_discount = 0;

    cart.forEach((item, i) => {
      if (item.discount) {
        total_price =
          total_price +
          (Number(item.price) -
            (Number(item.price) / 100) * Number(item.discount)) *
            Number(item.count);
      } else {
        total_price = total_price + Number(item.price) * Number(item.count);
      }
      total_price_without_discount =
        total_price_without_discount + Number(item.price) * Number(item.count);
    });

    let promo_discount_value =
      promo && promo.discount
        ? (total_price / 100) * Number(promo.discount)
        : 0;
    return {
      TOTAL_PRICE: total_price - promo_discount_value,
      TOTAL_PRICE_WITHOUT_DISCOUNT: total_price_without_discount,
      TOTAL_PRODUCTS: cart.length,
      PROMO_DISCOUNT: promo_discount_value,
    };
  } else
    return {
      TOTAL_PRICE: 0,
      TOTAL_PRICE_WITHOUT_DISCOUNT: 0,
      TOTAL_PRODUCTS: 0,
      PROMO_DISCOUNT: 0,
    };
}
