import { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MobileMenu from './MobileMenu/MobileMenu';
import { BurgerIcon, LogoIcon, CloseIcon } from '../../assets/icons/icons';
import phone from '../../assets/icons/phone-icon.png';
import './Header.css';
import { CONNECT_PHONE, NAV_LINKS_LIST } from '../../assets/utils/constants';

function Header() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleNavMenu() {
    setIsMenuOpen((prevState) => !prevState);
  }

  return (
    <header
      className={`header ${
        location.pathname === '/cart' ? 'header_hidden' : ''
      }`}
    >
      <div className="header__container">
        <Link className="header__main-link" to="/">
          {LogoIcon({
            mainClassName: 'header__logo-icon',
            fillClassName: 'header__logo-icon-fill',
            secondaryFillClassName: 'header__logo-icon-color-fill',
          })}
        </Link>

        <nav className="header__nav">
          <ul className="header__nav-list">
            {NAV_LINKS_LIST.map((item) => (
              <li className="header__nav-item" key={item.link}>
                <NavLink
                  className={({ isActive }) =>
                    'header__link' +
                    (item.isDisabled ? ' header__link_disabled' : '') +
                    (isActive ? ' header__link_selected' : '')
                  }
                  to={`/${item.link}`}
                >
                  <p className="header__link-text">{item.label}</p>
                  <div className="header__text-underline" />
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="header__btns-block">
          <a className="header__contact" href={`tel:${CONNECT_PHONE.value}`}>
            <img className="header__contact-icon" src={phone} alt="" />
            <p className="header__link-text header__link-text_type_phone">
              {CONNECT_PHONE.label}
            </p>
          </a>

          <div className="header__menu-btns">
            <button
              className={`header__menu-btn header__menu-btn_type_burger ${
                !isMenuOpen ? 'header__menu-btn_visible' : ''
              }`}
              type="button"
              aria-label="Open mobile menu"
              onClick={toggleNavMenu}
            >
              {BurgerIcon({
                mainClassName: 'header__menu-icon',
                fillClassName: 'header__menu-icon-fill',
              })}
            </button>

            <button
              className={`header__menu-btn header__menu-btn_type_close ${
                isMenuOpen ? 'header__menu-btn_visible' : ''
              }`}
              type="button"
              aria-label="Close mobile menu"
              onClick={toggleNavMenu}
            >
              {CloseIcon({
                mainClassName: 'header__menu-icon',
                strokeClassName: 'header__menu-icon-stroke',
              })}
            </button>
          </div>
        </div>
      </div>

      <MobileMenu isOpen={isMenuOpen} onClose={toggleNavMenu} />
    </header>
  );
}

export default Header;
