import { useEffect, useState } from 'react';
import NewsCard from '../../NewsCard/NewsCard';
import './NewsMain.css';
import NewsLinkBtn from '../NewsLinkBtn/NewsLinkBtn';
import mainApi from '../../../assets/api/MainApi';
import { MAIN_INFO_LIMIT, NEWS_LINK } from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function NewsMain() {
  const [news, setNews] = useState([]);
  const [isPreloader, setIsPreloader] = useState(true);

  useEffect(() => {
    setIsPreloader(true);
    mainApi
      .getAllNews({ start: 0, limit: MAIN_INFO_LIMIT })
      .then((res) => setNews(res))
      .catch((err) => console.log(err))
      .finally(() => setIsPreloader(false));
  }, []);

  return (
    <div className="main-news">
      <div className="main-news__link-container">
        <p className="main-news__title">
          BIG <span className="main-news__title_type_color">NEEEEEWS</span>
        </p>
        <div className="main-news__link-btn">
          <NewsLinkBtn link={NEWS_LINK} text="All News" />
        </div>
      </div>

      <div className="main-news__cards">
        {!isPreloader ? (
          <ul className="main-news__list">
            {news.map((news) => (
              <li className="main-news__item" key={news.id}>
                <NewsCard data={news} />
              </li>
            ))}
          </ul>
        ) : (
          <MiniPreloader />
        )}
      </div>

      <div className="main-news__link-btn main-news__link-btn_type_mobile">
        <NewsLinkBtn link={NEWS_LINK} text="All News" />
      </div>
    </div>
  );
}

export default NewsMain;
