import banner from '../../assets/images/growth/banner.webp';
import img from '../../assets/images/growth/heading-img.webp';
import { GROWTH_INFO_LIST } from '../../assets/utils/constants';
import './Growth.css';

function Growth() {
  return (
    <section className="growth">
      <img className="growth__banner" src={banner} alt="Рост" />
      <div className="growth__block growth__block_type_heading">
        <img className="growth__heading-img" src={img} alt="" />
        <h2 className="growth__title">
          BIG BRO PRO постоянно растёт и&nbsp;развивается, отлично проследить
          это можно благодаря интервью Сергея Лосева и подкастам с его участием.
        </h2>
      </div>

      <ul className="growth__list">
        {GROWTH_INFO_LIST.map((item) => (
          <li className="growth__block" key={item.label}>
            <div className="growth__text-box">
              <div className="growth__label-box">
                <p className="growth__label">{item.label}</p>
              </div>
              <p className="growth__block-title">{item.title}</p>
              <p className="growth__text">{item.text}</p>
            </div>

            <div className="growth__video-box">
              <iframe
                className="growth__video"
                width="560"
                height="315"
                src={item.link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default Growth;
