import { Navigate, Route, Routes } from 'react-router-dom';
import banner from '../../assets/images/family/banner.webp';
import bannerMobile from '../../assets/images/family/banner-mobile.webp';
import './Family.css';
import PageNav from '../PageNav/PageNav';
import FamilyList from './FamilyList/FamilyList';
import { FAMILY_LINK } from '../../assets/utils/constants';

function Family({ categories }) {
  return (
    <div className="family">
      <img className="family__banner" src={banner} alt="FAMILY" />
      <img
        className="family__banner family__banner_type_mobile"
        src={bannerMobile}
        alt="FAMILY"
      />
      {categories ? (
        <div className="family__nav">
          <PageNav
            list={categories.map((item) => ({
              label: item.name,
              link: item.translit_name,
            }))}
          />
        </div>
      ) : null}

      <p className="family__title">
        Познакомимся поближе с&nbsp;теми, без кого не было бы BIG BRO PRO.
      </p>

      {categories ? (
        <div className="family__content">
          <Routes>
            <Route
              index
              element={
                <Navigate
                  to={`/${FAMILY_LINK}/${categories[0]?.translit_name}`}
                />
              }
            />
            <Route path="/:category_name" element={<FamilyList />} />
          </Routes>
        </div>
      ) : null}
    </div>
  );
}

export default Family;
