import HeadingMain from './HeadingMain/HeadingMain';
import LinkCards from './LinkCards/LinkCards';
import FamilyMain from './FamilyMain/FamilyMain';
import NewsMain from './NewsMain/NewsMain';
import InstagramFeed from './InstagramFeed/InstagramFeed';
import './Main.css';
import GigsMain from './GigsMain/GigsMain';

function Main({ categories }) {
  return (
    <main className="main">
      <HeadingMain />
      <LinkCards {...{ categories }} />
      <FamilyMain />
      <NewsMain />
      <GigsMain />
      <InstagramFeed />
    </main>
  );
}

export default Main;
