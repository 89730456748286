import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import './NewsCard.css';
import { NEWS_LINK } from '../../assets/utils/constants';
import { formatMediaPath } from '../../assets/utils/utils';

function NewsCard({ data }) {
  return (
    <div className="news-card">
      <Link
        className="news-card__link"
        to={`/${NEWS_LINK}/${data.translit_name}`}
      >
        <div className="news-card__date">
          <p className="news-card__date-text">
            {moment(data.date, 'YYYY-MM-DDTHH:mm:ssZ').format('D MMMM')}
          </p>
        </div>
        <img
          className="news-card__img"
          src={formatMediaPath(data.title_photo)}
          alt={data.title_photo?.alternativeText}
        />
        <p className="news-card__title">{data.title}</p>
        <p className="news-card__text">{data.description}</p>
      </Link>
    </div>
  );
}

export default NewsCard;
