import MiniPreloader from '../MiniPreloader/MiniPreloader';
import './MoreBtn.css';

function MoreBtn({ onClick, isPreloader }) {
  return (
    <button
      className={`more-btn ${isPreloader ? 'more-btn_disabled' : ''}`}
      type="button"
      onClick={onClick}
    >
      {!isPreloader ? <p className="more-btn__text">Ещё</p> : <MiniPreloader />}
    </button>
  );
}

export default MoreBtn;
