import { useEffect, useState } from 'react';
import './ImageElement.css';
import { formatMediaPath } from '../../../../assets/utils/utils';

function ImageElement({ data }) {
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    const link = formatMediaPath(data);
    loadImage(link);
  }, [data]);

  const loadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsImgLoaded(true);
    };
    img.onerror = (err) => {
      console.log('img error:', err);
    };
  };

  return (
    <div className="img-element">
      {isImgLoaded ? (
        <img
          className="img-element__picture"
          src={formatMediaPath(data)}
          alt={data.alternativeText}
        />
      ) : null}
    </div>
  );
}

export default ImageElement;
