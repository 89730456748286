import { INSTA_PARSER_URL } from '../utils/constants';

class InstaApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  getPosts({ limit = 6 }) {
    let params = {};
    if (limit) params.limit = limit;

    return fetch(
      `${this._BASE_URL}/instagram/get_posts?` + new URLSearchParams(params),
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).then(this._checkResponse);
  }
}

const instaApi = new InstaApi({
  baseUrl: INSTA_PARSER_URL,
});

export default instaApi;
