import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import NewsLinkBtn from '../NewsLinkBtn/NewsLinkBtn';
import GigCard from './GigCard/GigCard';
import './GigsMain.css';
import mainApi from '../../../assets/api/MainApi';
import { MAIN_INFO_LIMIT, POSTERS_LINK } from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function GigsMain() {
  const [gigs, setGigs] = useState([]);
  const [isPreloader, setIsPreloader] = useState(true);

  useEffect(() => {
    const now = moment(moment(), 'YYYY-MM-DDTHH:mm:ssZ').format(
      'YYYY-MM-DDTHH-mm-ss'
    );

    setIsPreloader(true);
    mainApi
      .getAllGigs({
        start: 0,
        limit: MAIN_INFO_LIMIT,
        isActive: true,
        date: now,
      })
      .then((res) => {
        if (res.length > 0) {
          setGigs(res);
        } else {
          mainApi
            .getAllGigs({
              start: 0,
              limit: MAIN_INFO_LIMIT,
              isActive: false,
              date: now,
            })
            .then((res1) => setGigs(res1))
            .catch((err) => console.log(err))
            .finally(() => setIsPreloader(false));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsPreloader(false));
  }, []);

  return (
    <div className="main-gigs">
      <div className="main-gigs__container">
        <div className="main-gigs__link-container">
          <p className="main-gigs__title main-news__title">
            <span className="main-gigs__title_type_color">Bro</span> giIIgs
          </p>
          <div className="main-gigs__link-btn">
            <NewsLinkBtn link={POSTERS_LINK} text="All Posters" />
          </div>
        </div>

        <div className="main-gigs__cards">
          {!isPreloader ? (
            <ul className="main-gigs__list">
              {gigs.map((gig) => (
                <li className="main-gigs__item" key={gig.id}>
                  <GigCard data={gig} />
                </li>
              ))}
            </ul>
          ) : (
            <MiniPreloader />
          )}
        </div>

        <div className="main-gigs__link-btn main-gigs__link-btn_type_mobile">
          <NewsLinkBtn link={POSTERS_LINK} text="All Posters" />
        </div>
      </div>
    </div>
  );
}

export default GigsMain;
