import "./RunningLine.css";

function RunningLine({ children }) {
  const lineArr = Array(20).fill(children);

  return (
    <section className="running-line">
      {lineArr.map((line, i) => (
        <div className="running-line__box" key={`running-line-${i}`}>
          {line}
        </div>
      ))}
    </section>
  );
}

export default RunningLine;
