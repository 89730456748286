import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ArticleNews.css';
import mainApi from '../../../assets/api/MainApi';
import Article from '../Article';
import { NEWS_TYPE } from '../../../assets/utils/constants';

function ArticleNews() {
  const { translit_name } = useParams();
  const [data, setData] = useState(undefined);
  const [isPreloader, setIsPreloader] = useState(true);

  useEffect(() => {
    if (!translit_name) return;

    setIsPreloader(true);
    mainApi
      .getNewsByName({ translit_name })
      .then((res) => setData(res))
      .catch((err) => console.log(err))
      .finally(() => setIsPreloader(false));
  }, [translit_name]);

  return data ? (
    <Article type={NEWS_TYPE} title="News" {...{ data, isPreloader }} />
  ) : null;
}

export default ArticleNews;
