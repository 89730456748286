import { useEffect, useState } from 'react';
import './Events.css';
import moment from 'moment';
import mainApi from '../../../assets/api/MainApi';
import {
  GIGS_LIMIT,
  MAIN_INFO_LIMIT,
  SOCIAL_LINKS_LIST,
} from '../../../assets/utils/constants';
import GigCard from '../../Main/GigsMain/GigCard/GigCard';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';

function Events() {
  const [futureGigs, setFutureGigs] = useState([]);
  const [pastGigs, setPastGigs] = useState([]);
  const [isPreloader, setIsPreloader] = useState({
    future: true,
    past: true,
  });

  useEffect(() => {
    getGigs({ limit: MAIN_INFO_LIMIT, isActive: true, preloader: 'future' });
    getGigs({
      limit: GIGS_LIMIT,
      isActive: false,
      preloader: 'past',
    });
  }, []);

  function getGigs({ limit, isActive, preloader }) {
    const now = moment(moment(), 'YYYY-MM-DDTHH:mm:ssZ').format(
      'YYYY-MM-DDTHH-mm-ss'
    );

    setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: true }));
    mainApi
      .getAllGigs({
        start: 0,
        limit,
        isActive,
        date: now,
        isLegendary: true,
      })
      .then((res) => {
        isActive ? setFutureGigs(res) : setPastGigs(res);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: false }));
      });
  }

  return (
    <section className="events">
      {!isPreloader.future && !isPreloader.past ? (
        <div className="events__content">
          {futureGigs?.length > 0 ? (
            <div className="events__block">
              <h3 className="events__title">-СКОРО-</h3>
              <div className="events__cards">
                <ul className="events__list">
                  {futureGigs.map((item) => (
                    <li className="events__item" key={item.id}>
                      <GigCard data={item} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}

          <div className="events__block">
            <h3 className="events__subtitle">
              Top of the top в истории BIG BRO PRO. Как это было?
            </h3>
            <div className="events__cards">
              <ul className="events__list">
                {pastGigs.map((item) => (
                  <li className="events__item" key={item.id}>
                    <GigCard data={item} isMonochrome />
                  </li>
                ))}
                <li className="events__item events__item_type_info">
                  <div className="events__card">
                    <div className="events__card-text-box">
                      <p className="events__card-title">
                        Следите за разделом! масса крутейших ивентов впереди.
                      </p>
                      <p className="events__card-text">
                        Впоследствии они обязательно окажутся здесь!
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <MiniPreloader isBig />
      )}

      <div className="events__social-info">
        <p className="events__social-title">
          Больше о BIG BRO PRO — в&nbsp;наших&nbsp;соцсетях!
        </p>
        <ul className="events__social-list">
          {SOCIAL_LINKS_LIST.map((item, i) => (
            <li className="events__social-item" key={i}>
              <a
                className="events__social-link"
                href={item.href}
                target="_blank"
                rel="noreferrer"
              >
                <img className="events__social-icon" src={item.icon} alt="" />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default Events;
