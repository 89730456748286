import { TicketIcon } from '../../../../assets/icons/icons';
import './TicketsElement.css';

function TicketsElement({ tickets_url }) {
  return (
    <a
      className="ticket-element"
      href={tickets_url}
      target="_blank"
      rel="noreferrer"
    >
      <TicketIcon
        mainClassName="ticket-element__icon"
        fillClassName="ticket-element__icon-fill"
      />
      <p className="ticket-element__text">Купить билет</p>
    </a>
  );
}

export default TicketsElement;
