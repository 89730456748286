import { useLocation } from 'react-router-dom';
import { LinkArrowIcon } from '../../../assets/icons/icons';
import './OthersContent.css';
import {
  OTHERS_DESIGN_LINK,
  OTHERS_EVENTS_LINK,
} from '../../../assets/utils/constants';

function OthersContent({ data }) {
  const { pathname } = useLocation();

  return (
    <div className="others-content">
      <div className="others-content__heading">
        <div className="others-content__avatar-box">
          <div className="others-content__avatar">
            {data.icon ? (
              <img className="others-content__icon" src={data.icon} alt="" />
            ) : null}
          </div>

          {data.source?.link ? (
            <a
              className="others-content__link"
              href={data.source.link}
              target="_blank"
              rel="noreferrer"
            >
              <p className="others-content__link-text">{data.source.label}</p>
              <LinkArrowIcon
                mainClassName="others-content__link-icon"
                fillClassName="others-content__link-icon-fill"
              />
            </a>
          ) : null}
        </div>

        <div className="others-content__article">
          {data.text ? (
            <p className="others-content__text">{data.text}</p>
          ) : null}
          {data.title ? (
            <p className="others-content__title">{data.title}</p>
          ) : null}
          {data.subtitle ? (
            <p
              className={`others-content__subtitle ${
                pathname.includes(OTHERS_EVENTS_LINK)
                  ? 'others-content__subtitle_color_purple'
                  : pathname.includes(OTHERS_DESIGN_LINK)
                  ? 'others-content__subtitle_color_mint'
                  : ''
              }`}
            >
              {data.subtitle}
            </p>
          ) : null}
        </div>
      </div>

      <ul className="others-content__grid">
        {Array(4)
          .fill('')
          .map((_, i) => (
            <li className="others-content__card" key={i}>
              {data.imgs[i] ? (
                <img
                  className="others-content__card-img"
                  src={data.imgs[i]}
                  alt=""
                />
              ) : null}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default OthersContent;
