import banner from '../../../assets/images/us/what-we-do-banner.webp';
import photo from '../../../assets/images/us/what-we-do-photo.webp';
import './WhatWeDo.css';

function WhatWeDo() {
  return (
    <section className="about-we-do">
      <div className="about-we-do__heading">
        <img className="about-we-do__heading-img" src={banner} alt="" />
        <div className="about-we-do__article about-big-bro__article">
          <h3 className="about-we-do__title">
            BIG BRO BRO — это творческая семья, под крылом которой собраны
            красивые, талантливые и целеустремленные люди, с единой глобальной
            целью — развиваться самим и развивать этот Мир.
          </h3>
          <p className="about-we-do__text about-big-bro__text">
            Творцы, революционеры, смелые и сильные духом — то, что выделяет
            каждого члена семьи в общем кругу. Весь накопленный годами (если
            хотите, веками) опыт направлен на созидание.
          </p>
          <p className="about-we-do__text about-big-bro__text">
            <span className="about-big-bro__text_weight_bold">
              Наш формат — бизнес в виде экосистемы.
            </span>{' '}
            Все элементы деятельности компании тесно взаимосвязаны и переплетены
            друг с другом. Артисты, поиск и зажигание новых звёзд, организация
            мероприятий и вечеринок, линия одежды, музыкальный лейбл,
            многочисленные коллаборации — это далеко не полный список того, чем
            мы занимаемся.
          </p>
        </div>
      </div>

      <img className="about-we-do__img" src={photo} alt="" />

      <div className="about-we-do__info">
        <p className="about-we-do__info-title">Наши ценности:</p>
        <ul className="about-we-do__info-list">
          <li className="about-we-do__info-item">
            <p className="about-we-do__info-subtitle about-big-bro__subtitle">
              Созидание
            </p>
            <p className="about-we-do__text about-we-do__text_color_white about-big-bro__text">
              Создаём новое и улучшаем старое, а&nbsp;результаты работы
              вдохновляют каждый&nbsp;раз шагать всё выше и выше.
            </p>
          </li>
          <li className="about-we-do__info-item">
            <p className="about-we-do__info-subtitle about-big-bro__subtitle">
              Профессионализм
            </p>
            <p className="about-we-do__text about-we-do__text_color_white about-big-bro__text">
              Во всём: в коллаборациях, ивентах и в тех малых деталях, о которых
              вы даже не догадываетесь. Только так получаются проекты, которые
              запоминаются навсегда.
            </p>
          </li>
          <li className="about-we-do__info-item">
            <p className="about-we-do__info-subtitle about-big-bro__subtitle">
              Респектабельность
            </p>
            <p className="about-we-do__text about-we-do__text_color_white about-big-bro__text">
              Мы с огромным уважениям относимся к каждому, будь то многолетний
              бизнес-партнёр, рядовой сотрудник или новый клиент. И такое же
              отношение получаем в ответ :) Да, взаимоуважение царит и в общении
              с конкурентами.
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default WhatWeDo;
