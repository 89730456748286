import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import './BroGigs.css';
import mainApi from '../../assets/api/MainApi';
import { NEWS_LIMIT } from '../../assets/utils/constants';
import GigCard from '../Main/GigsMain/GigCard/GigCard';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import MoreBtn from '../MoreBtn/MoreBtn';

const limit = NEWS_LIMIT - 1;
const count = {
  future: 0,
  past: 0,
};

function BroGigs() {
  const [futureGigs, setFutureGigs] = useState([]);
  const [pastGigs, setPastGigs] = useState([]);
  const [isMore, setIsMore] = useState({
    future: false,
    past: false,
  });
  const [isPreloader, setIsPreloader] = useState({
    future: true,
    past: true,
    moreFuture: false,
    morePast: false,
  });

  useEffect(() => {
    getGigs({ start: 0, isActive: true, preloader: 'future' });
    getGigs({ start: 0, isActive: false, preloader: 'past' });
  }, []);

  function getGigs({ start, isActive, preloader }) {
    const now = moment(moment(), 'YYYY-MM-DDTHH:mm:ssZ').format(
      'YYYY-MM-DDTHH-mm-ss'
    );

    setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: true }));
    mainApi
      .getAllGigs({
        start,
        limit: NEWS_LIMIT,
        isActive,
        date: now,
      })
      .then((res) => {
        const gigs = res.slice(0, limit);
        if (isActive) {
          setFutureGigs(start === 0 ? gigs : (prevArr) => prevArr.concat(gigs));
          setIsMore((prevVal) => ({
            ...prevVal,
            future: res.length === NEWS_LIMIT,
          }));
          count.future += 1;
        } else {
          setPastGigs(start === 0 ? gigs : (prevArr) => prevArr.concat(gigs));
          setIsMore((prevVal) => ({
            ...prevVal,
            past: res.length === NEWS_LIMIT,
          }));
          count.past += 1;
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: false }));
      });
  }

  function moreFutureClickHandler() {
    getGigs({
      start: count.past * limit,
      isActive: true,
      preloader: 'moreFuture',
    });
  }

  function morePastClickHandler() {
    getGigs({
      start: count.past * limit,
      isActive: false,
      preloader: 'morePast',
    });
  }

  return (
    <section className="brogigs">
      <h2 className="brogigs__title">
        <span className="brogigs__title_type_color">Bro</span> giIIgs
      </h2>

      {!isPreloader.future && !isPreloader.past ? (
        <>
          {futureGigs?.length > 0 ? (
            <div className="brogigs__block">
              <h3 className="brogigs__subtitle">-СКОРО-</h3>
              <div className="brogigs__cards">
                <ul className="brogigs__list">
                  {futureGigs.map((item) => (
                    <li className="brogigs__item" key={item.id}>
                      <GigCard data={item} isGrid />
                    </li>
                  ))}
                </ul>
                {isMore.future ? (
                  <MoreBtn
                    onClick={moreFutureClickHandler}
                    isPreloader={isPreloader.moreFuture}
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          <div className="brogigs__block">
            <div className="brogigs__cards">
              <ul className="brogigs__list">
                {pastGigs.map((item) => (
                  <li className="brogigs__item" key={item.id}>
                    <GigCard data={item} isMonochrome isGrid />
                  </li>
                ))}
              </ul>
              {isMore.past ? (
                <MoreBtn
                  onClick={morePastClickHandler}
                  isPreloader={isPreloader.morePast}
                />
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <MiniPreloader isBig />
      )}
    </section>
  );
}

export default BroGigs;
