import { Link } from 'react-router-dom';
import './WhyUs.css';
import banner from '../../../assets/images/us/why-us-banner.webp';
import bigbro from '../../../assets/images/us/bigbro.webp';
import bro1 from '../../../assets/images/us/bro1.webp';
import bro2 from '../../../assets/images/us/bro2.webp';
import bro3 from '../../../assets/images/us/bro3.webp';
import bro4 from '../../../assets/images/us/bro4.webp';
import bro5 from '../../../assets/images/us/bro5.webp';
import bro6 from '../../../assets/images/us/bro6.webp';
import bro7 from '../../../assets/images/us/bro7.webp';
import { FAMILY_LINK } from '../../../assets/utils/constants';

const BRO_LIST = [bigbro, bro1, bro2, bro3, bro4, bro5, bro6, bro7];

function WhyUs() {
  return (
    <div className="about-why">
      <div className="about-why__heading">
        <img className="about-why__heading-img" src={banner} alt="" />
        <div className="about-why__article about-big-bro__article">
          <p className="about-why__text about-big-bro__text">
            В 2019 году в проект Сергея Лосева пришёл первый диджей — Нарек
            Меликян (DJ NÉK), а к сегодняшнему дню к команде присоединились ещё
            пятеро крутейших диджеев, талантливые фотограф и рилсмейкер, а также
            невероятная артистка. Помимо этого, мы сотрудничаем с разными
            ведущими Москвы и самобытной кавер-группой. Подробнее о каждом члене
            нашей команды вы можете узнать{' '}
            <Link className="about-why__text-link" to={`/${FAMILY_LINK}`}>
              здесь →
            </Link>
          </p>
          <p className="about-why__text about-big-bro__text">
            Работа в команде строится на опыте, репутации, авторитете и семейных
            ценностях. Как отметил Сергей:{' '}
            <span className="about-big-bro__text_weight_bold">
              «Во многих вопросах со своими протеже я выступаю в качестве
              Старшего брата»
            </span>
            . Вот он, настоящий Big Bro. А с частичкой Pro ещё легче: все
            участники продюсерского центра — профессионалы своего дела! Кстати,
            ещё мы лучшие в PROduction и PROmotion. Нескромно, но как есть.
          </p>
        </div>
      </div>

      <ul className="about-why__grid">
        {BRO_LIST.map((item, i) => (
          <li className="about-why__grid-item" key={i}>
            <img className="about-why__grid-img" src={item} alt="" />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WhyUs;
