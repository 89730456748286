import { useEffect, useState } from 'react';
import NewsCard from '../NewsCard/NewsCard';
import banner from '../../assets/images/bignews/banner.webp';
import bannerMobile from '../../assets/images/bignews/banner-mobile.webp';
import './BigNews.css';
import mainApi from '../../assets/api/MainApi';
import { NEWS_LIMIT } from '../../assets/utils/constants';
import MoreBtn from '../MoreBtn/MoreBtn';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

const limit = NEWS_LIMIT - 1;
let count = 0;

function BigNews() {
  const [news, setNews] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [isPreloader, setIsPreloader] = useState({
    main: true,
    more: false,
  });

  useEffect(() => {
    getNews({ start: 0, preloader: 'main' });
  }, []);

  function getNews({ start, preloader }) {
    setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: true }));
    mainApi
      .getAllNews({ start, limit: NEWS_LIMIT })
      .then((res) => {
        const news = res.slice(0, limit);
        setNews(start === 0 ? news : (prevArr) => prevArr.concat(news));
        setIsMore(res.length === NEWS_LIMIT);
        count += 1;
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsPreloader((prevVal) => ({ ...prevVal, [preloader]: false }));
      });
  }

  function moreBtnClickHandler() {
    getNews({ start: count * limit, preloader: 'more' });
  }

  return (
    <section className="news">
      <img className="news__banner" src={banner} alt="BIG NEEEWS" />
      <img
        className="news__banner news__banner_type_mobile"
        src={bannerMobile}
        alt="BIG NEEEWS"
      />

      {!isPreloader.main ? (
        <div className="news__cards">
          <ul className="news__grid-list">
            {news.map((item) => (
              <li className="news__card" key={item.id}>
                <NewsCard data={item} />
              </li>
            ))}
          </ul>
          {isMore ? (
            <MoreBtn
              onClick={moreBtnClickHandler}
              isPreloader={isPreloader.more}
            />
          ) : null}
        </div>
      ) : (
        <MiniPreloader isBig />
      )}
    </section>
  );
}

export default BigNews;
