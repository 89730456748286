import { MEDIUM_IMG_FORMAT } from '../../../../assets/utils/constants';
import { formatMediaPath } from '../../../../assets/utils/utils';
import './PhotoGrid.css';

function PhotoGrid({ data }) {
  return (
    <ul className="photo-grid">
      {data.map((item) => (
        <li className="photo-grid__item" key={item.id}>
          <img
            className="photo-grid__img"
            src={formatMediaPath(item.photo, MEDIUM_IMG_FORMAT)}
            alt={item.photo?.alternativeText}
          />
        </li>
      ))}
    </ul>
  );
}

export default PhotoGrid;
