import "./Cart.css";
import { Order } from "./Order/Order";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Goods from "./Goods/Goods";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import PromoCheckPopup from "./PromoCheckPopup/PromoCheckPopup";
import OrderPopup from "./OrderPopup/OrderPopup";
import { getFinalPrice, getTotal, sendMetriс } from "../../assets/utils/utils";
import OrderErrorPopup from "./OrderErrorPopup/OrderErrorPopup";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import logoCart from "../../assets/images/merch/cartLogo.webp";
import logoCartMobile from "../../assets/images/merch/cartLogoMobile.webp";
import bgCart from "../../assets/images/merch/bgCart.webp";

function Cart({ cart, handleToCartBtn, handleRemoveFromCart, setCart }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(0);

  const [popups, setPopups] = useState({
    order_created: false,
    //order_created: true,
    order_created_error: false,
  });

  const [preloaders, setPreloaders] = useState({
    order_creating: false,
  });

  const [promo, setPromo] = useState({
    value: "",
    isValid: false,
  });

  const [createdOrder, setCreatedOrder] = useState(undefined);

  const [personal, setPersonal] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    submit_type: "phone",
    city: "",
    street: "",
    house: "",
    apartment: "",
    type: "free",
    checkbox: false,
  });

  const [personalValidity, setPersonalValidity] = useState({
    phone: {},
    email: {},
  });

  const [total, setTotal] = useState({
    PRICE: 0,
    ITEMS_PRICE: 0,
    ITEMS_DISCOUNT: 0,
    ITEMS_COUNT: 0,
    PRODUCTS: 0,
    PROMO_DISCOUNT: 0,

    DELIVERY_PRICE: 0,
  });

  function handleNextStep() {
    switch (step) {
      case 0:
        setStep((prevValue) => prevValue + 1);
        window.scrollTo(0, 0);
        //sendMetriс('reachGoal', `CREATE_ORDER_${step + 1}`)
        break;

      case 1:
        setPreloaders((prevValue) => ({
          ...prevValue,
          order_creating: true,
        }));
        const cartPrevArray = cart;
        let cartArray = [];
        setCart(cartArray);
        localStorage.setItem("cart", JSON.stringify(cartArray));
        setPromo({
          value: "",
          isValid: false,
        });
        break;
      default:
        break;
    }
  }

  function handleStepBack() {
    if (step !== 0) {
      setStep((prevValue) => prevValue - 1);
    }
  }

  useEffect(() => {
    if (!cart || cart.length === 0) return;
    // mainApi.calculateOrder({
    //  data:
    cart.map((item) => {
      return {
        item_id: item._id,
        size: item.selected_size ? item.selected_size.name : null,
        amount: `${item.count}`,
      };
    });
    //   type: personal.type,
    //  promo_code: promo.isValid && promo.value ? promo.value : null,
    // })
    // .then((res) => {
    setTotal((prevValue) => ({
      ...prevValue,
      PRICE: 0,
      ITEMS_PRICE: 0,
      //   ITEMS_DISCOUNT: res.items_discount,
      ITEMS_COUNT: cart.length,
      //   PROMO_DISCOUNT: res.discount,
      // DELIVERY_PRICE: res.delivery_price,
    }));
    // console.log(res)
    //   })
    //  .catch((err) => {
    //    console.log(err)
    //  })
  }, [personal.type, cart, promo]);

  const isPersonalValid =
    personal.phone &&
    personal.first_name &&
    personal.last_name &&
    personal.email &&
    personalValidity.email.validState &&
    personalValidity.phone.validState &&
    personal.checkbox;

  useEffect(() => {
    if (!searchParams) return;

    const paid = JSON.parse(searchParams.get("paid"));
    const order = JSON.parse(searchParams.get("order"));
    console.log({ paid, order });
    if (!paid && order) {
      let formattedPhoneValue = "";
      if (["7", "8", "9"].indexOf(order.phone[0]) > -1) {
        if (order.phone[0] === "9") order.phone = "7" + order.phone;

        let firstSimbols = order.phone[0] === "8" ? "8" : "+7";
        formattedPhoneValue = firstSimbols + " ";

        if (order.phone.length > 1) {
          formattedPhoneValue += "(" + order.phone.substring(1, 4);
        }
        if (order.phone.length >= 5) {
          formattedPhoneValue += ") " + order.phone.substring(4, 7);
        }
        if (order.phone.length >= 8) {
          formattedPhoneValue += "-" + order.phone.substring(7, 9);
        }
        if (order.phone.length >= 10) {
          formattedPhoneValue += "-" + order.phone.substring(9, 11);
        }
      } else {
        formattedPhoneValue = "+" + order.phone.substring(0, 16);
      }
      setPersonal((prevValue) => ({
        ...prevValue,
        phone: formattedPhoneValue,
        email: order.email,
        first_name: order.first_name,
        last_name: order.last_name,
        submit_type: order.submit_type,
        city: order.address.city ? order.address.city : "",
        street: order.address.street ? order.address.street : "",
        house: order.address.house ? order.address.house : "",
        apartment: order.address.apartment ? order.address.apartment : "",
        type: order.address.type,
        checkbox: true,
      }));
      setStep(1);
      setPersonalValidity((prevValue) => ({
        ...prevValue,
        phone: {
          errorMessage: "",
          validState: true,
        },
        email: {
          errorMessage: "",
          validState: true,
        },
      }));
      if (order.promo_code) {
        setPromo({
          value: order.promo_code,
          isValid: true,
          discount: order.discount,
        });
      }

      searchParams.delete("order");
      searchParams.delete("paid");
      setSearchParams(searchParams);
    } else if (paid && order) {
      const cartPrevArray = cart;
      setCreatedOrder(order);
      setPopups((prevValue) => ({
        ...prevValue,
        order_created: true,
      }));
      let cartArray = [];
      setCart(cartArray);
      localStorage.setItem("cart", JSON.stringify(cartArray));
      setStep(0);
      setPromo({
        value: "",
        isValid: false,
      });
      // sendMetriс('reachGoal', 'CREATE_ORDER_2')
      window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          purchase: {
            products: cartPrevArray.map((product, i) => {
              return {
                id: product?._id,
                name: `${product?.name} ${product?._id}`,
                category: product
                  ? `Женская одежда/${product.category.title.ru}/${product.sub_category.title.ru}`
                  : "",
                price: getFinalPrice(product, promo),
                quantity: product.count,
                variant: product.selected_size
                  ? product.selected_size.name
                  : "Без размера",
              };
            }),
            actionField: {
              id: `№${("00000000" + order.ai_id).slice(-8)}`,
              goal_id: 301991107,
            },
          },
        },
      });
      searchParams.delete("order");
      searchParams.delete("paid");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  let navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <div className="cart">
      <Helmet>
        <title>BigBroPro - Корзина</title>
      </Helmet>
      <div className="cart__header-box">
        <img className="cart__header-logo" alt="" src={logoCart}></img>
        <img
          className="cart__header-logo_mobile"
          alt=""
          src={logoCartMobile}
        ></img>
        <p className="cart__title">
          {step === 0 ? "Корзина" : ""}
          {step === 1 ? "Оформление заказа" : ""}
        </p>
        <button onClick={() => handleClose()} className="cart__button_close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
          >
            <rect width="33" height="33" rx="16.5" fill="#A200CB" />
            <rect
              x="9"
              y="22.8492"
              width="21"
              height="2"
              rx="1"
              transform="rotate(-45 9 22.8492)"
              fill="white"
            />
            <rect
              width="21"
              height="2"
              rx="1"
              transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 25.2637 22.8492)"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="cart__content">
        <div className="cart__steps">
          {step > 0 ? (
            <button
              className="cart__go-back"
              type="button"
              onClick={handleStepBack}
            >
              ‹ Назад
            </button>
          ) : null}
          {step === 0 ? (
            <Goods
              cart={cart}
              handleToCartBtn={handleToCartBtn}
              handleRemoveFromCart={handleRemoveFromCart}
            />
          ) : null}

          {step === 1 ? (
            <PersonalInfo
              personal={personal}
              setPersonal={setPersonal}
              personalValidity={personalValidity}
              setPersonalValidity={setPersonalValidity}
            />
          ) : null}
        </div>
        <Order
          cart={cart}
          step={step}
          handleNextStep={handleNextStep}
          promo={promo}
          setPromo={setPromo}
          isPersonalValid={isPersonalValid}
          isPreloaderVisible={preloaders.order_creating}
          total={total}
        />
        <img alt="" src={bgCart} className="cart__bg"></img>
      </div>

      <OrderPopup
        isOpen={popups.order_created}
        popupName={"order_created"}
        setOpen={setPopups}
        order={createdOrder}
        ai_id={
          createdOrder ? `№${("00000000" + createdOrder.ai_id).slice(-8)}` : ""
        }
      />

      <OrderErrorPopup
        isOpen={popups.order_created_error}
        popupName={"order_created_error"}
        setOpen={setPopups}
      />
    </div>
  );
}

export default Cart;
