import { CloseIcon, PopupCloseIcon } from '../../assets/icons/icons';
import {
  HEADING_BLOCK,
  IMAGE_BLOCK,
  PARAGRAPH_BLOCK,
} from '../../assets/utils/constants';
import './Article.css';
import { useNavigate } from 'react-router-dom';
import ImageElement from './Elements/ImageElement/ImageElement';
import TextElement from './Elements/TextElement/TextElement';
import DateElement from './Elements/DateElement/DateElement';
import TicketsElement from './Elements/TicketsElement/TicketsElement';
import { formatMediaPath } from '../../assets/utils/utils';
import Poster from './Elements/Poster/Poster';
import PhotoGrid from './Elements/PhotoGrid/PhotoGrid';
import MiniPreloader from '../MiniPreloader/MiniPreloader';

function Article({ data, type, title, isPreloader }) {
  const navigate = useNavigate();

  function handleClose() {
    navigate(-1);
  }

  return (
    <div className={`article ${type ? `article_type_${type}` : ''}`}>
      {!isPreloader ? (
        <>
          <div className="article__heading">
            <p className="article__type">{title}</p>
            <button
              className="article__close-btn article__close-btn_type_mobile"
              type="button"
              onClick={handleClose}
            >
              <CloseIcon
                mainClassName="article__close-icon"
                strokeClassName="article__close-icon-stroke"
              />
            </button>
          </div>

          <button
            className="article__close-btn"
            type="button"
            onClick={handleClose}
          >
            <PopupCloseIcon
              mainClassName="article__close-icon"
              fillClassName="article__close-icon-fill"
            />
          </button>

          <div className="article__content">
            {data.title_photo ? (
              <img
                className="article__title-img"
                src={formatMediaPath(data.title_photo)}
                alt={data.title_photo.alternativeText}
              />
            ) : null}

            <div className="article__main-content">
              {data.date ? (
                <DateElement date={data.date} {...{ type }} />
              ) : null}

              {data.text?.map((item, i) =>
                item.type === HEADING_BLOCK || item.type === PARAGRAPH_BLOCK ? (
                  <TextElement data={item} key={i} />
                ) : item.type === IMAGE_BLOCK ? (
                  <ImageElement data={item.image} key={i} />
                ) : null
              )}
            </div>

            {data.photos?.length > 0 ? <PhotoGrid data={data.photos} /> : null}

            {data.poster ? <Poster data={data.poster} /> : null}

            {data.tickets_url ? (
              <TicketsElement tickets_url={data.tickets_url} />
            ) : null}
          </div>
        </>
      ) : (
        <MiniPreloader isBig />
      )}
    </div>
  );
}

export default Article;
